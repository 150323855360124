<template>
    <chart-panel class="chart-container" border>
        <div class="d-flex title-area" style="border-bottom: 1px solid rgb(224, 225, 230);">
            <div class="d-flex">
                <div class="my-3 ml-3">{{ titleDisplay }}</div>
            </div>

            <v-spacer></v-spacer>

            <v-menu offset-y left v-model="chartSettings">
                <template v-slot:activator="{ on }">
                    <v-btn text icon>
                        <v-icon v-on="on" @click="close = false">more_vert</v-icon>
                    </v-btn>
                </template>
                <slot name="menu">
                    <v-card flat style="background: #fff; padding: 10px">
                        <slot name="extra-menu-items"></slot>
                        <v-btn class="pa-0" text @click="printChart">
                            <v-icon class="mr-2" style="color:rgba(0, 0, 0, 0.54)">fa-print</v-icon>
                            Print PDF
                        </v-btn>
                        <br>
                        <v-btn class="pa-0" text @click="exportChart">
                            <v-icon class="mr-2" style="color:rgba(0, 0, 0, 0.54)">fa-download</v-icon>
                            Download Image
                        </v-btn>
                        <br>
                        <v-btn class="pa-0" text @click="exportCSV">
                            <v-icon class="mr-2" style="color:rgba(0, 0, 0, 0.54)">fa-download</v-icon>
                            Download CSV
                        </v-btn>
                        <br>
                        <v-btn v-if="type === 'ongoing'" class="pa-0" text @click="toggleCounts()">
                            <v-icon class="mr-2" style="color:rgba(0, 0, 0, 0.54)">fa-hashtag</v-icon>
                            {{ toggleItemName }}
                        </v-btn>
                    </v-card>
                </slot>
            </v-menu>
        </div>

        <div class="d-flex field-area">
            <v-spacer/>
            <fe-date-picker
                v-if="type === 'ongoing'"
                v-model="selectedDate"
                class="mr-3 placeholder-text"
                prependIcon="fa-calendar-alt"
                dateFormat="MM/DD/YYYY"
                placeholder="MM/DD/YYYY"
                emitWhenValid
                @input="dateInput"
            />

            <fe-remote-combo
                v-else
                v-model="selectedTimePeriod"
                class="mr-3"
                style="width: 160px"
                :items="timePeriodOptions"
                :clearable="false"
                @input="timePeriodInput"
            />
        </div>

        <div class="chart-area">
            <fe-spinner v-if="!ready" class="spinner"/>

            <no-enrollment-data v-else-if="dataEmpty"/>

            <data-unavailable v-else-if="error"/>

            <highcharts
                v-else
                :id="enrollmentId"
                ref="enrollmentChart"
                class="ec-chart"
                :options="chartConfig"
            />
        </div>

        <div v-if="hasScrollbar" class="chart-area expanded-printable">
            <highcharts
                :id="enrollmentId + '-expanded'"
                ref="enrollmentChartExpanded"
                class="ec-chart expanded-printable"
                :options="expandedChartConfig"
            />
        </div>
    </chart-panel>
</template>

<script>
import ChartPanel from '@/components/charts/ChartPanel'
import * as ChartExportUtil from '../common/util/ChartExports'
import DataUnavailable from '../modules/enrollmentReporting/messaging/DataUnavailable.vue'
import NoEnrollmentData from '../modules/enrollmentReporting/messaging/NoEnrollmentData.vue'

export default {
    name: 'EnrollmentChart',

    components: {
        ChartPanel,
        NoEnrollmentData,
        DataUnavailable
    },

    props: {
        data: {
            type: Object,
            required: true
        },
        title: {
            type: String,
            default: ''
        },
        type: {
            type: String,
            default: 'ongoing'
        },
        demographic: {
            type: String
        },
        date: {},
        year: {},
        toggle: {
            type: Boolean
        },
        ready: {
            type: Boolean,
            default: false
        },
        error: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            chartSettings: false,
            selectedDate: '',
            selectedDemographic: undefined,
            selectedTimePeriod: {},
            timePeriodOptions: [{
                name: 'This Year',
                id: '0'
            }, {
                name: 'Last Year',
                id: '-1'
            }, {
                name: 'Last 3 Years',
                id: '-3'
            }, {
                name: 'Last 5 Years',
                id: '-5'
            }],
            toggleEnabled: true,
        }
    },

    computed: {
        titleDisplay() {
            let title = this.title

            switch (this.data.show) {
                case 'demographic':
                    title += ' by ' + this.demographic
                    break;
                case 'district':
                    break;
                default:
                    title += ' for ' + this.data.name.substring(3)
            }
            return title
        },

        toggleItemName() {
            return (this.toggleEnabled ? 'Hide' : 'Show') + ' Counts on Chart'
        },

        enrollmentId() {
            return `${this.type}-enrollment-chart`
        },

        chartConfig() {
            return this.data.config[this.type]
        },

        dataEmpty() {
            let seriesData = this.data?.config[this.type].series[0]
            if (seriesData !== undefined && seriesData.data !== undefined) {
                return !seriesData.data.length || seriesData.data.every(item => item === 0)
            }
            return true
        },

        hasScrollbar() {
            return this.chartConfig.xAxis?.scrollbar?.enabled
        },

        expandedChartConfig() {
            let config = this.$_.cloneDeep(this.chartConfig)
            delete config.xAxis.min
            delete config.xAxis.max
            delete config.xAxis.scrollbar

            let height = config.series[0].data.length * 22
            config.chart.height = height + 'px'

            return config
        }
    },

    mounted() {
        this.selectedDate = this.$dayjs(this.date).format('MM/DD/YYYY')
        this.selectedTimePeriod = this.year
        if (this.type === 'ongoing') {
            this.toggleEnabled = this.toggle
        }
    },

    methods: {
        dateInput(date) {
            if (date) {
                let inputDate = this.$dayjs(date).format('MM/DD/YYYY')
                if (inputDate !== this.date) {
                    this.$emit('change', 'date', inputDate)
                }
            }
        },

        timePeriodInput(period) {
            if (period && period !== this.year) {
                this.$emit('change', 'timePeriod', period)
            }
        },

        printChart() {
            let id = this.enrollmentId
            if(this.hasScrollbar) {
                id += '-expanded'
            }
            ChartExportUtil.printChartById(id)
        },

        exportChart() {
            let ref = 'enrollmentChart'
            if (this.hasScrollbar) {
                ref += 'Expanded'
            }
            ChartExportUtil.exportChart(this.$refs[ref].chart, this.title ? this.title : 'Chart')
        },

        exportCSV() {
            this.$refs.enrollmentChart.chart.downloadCSV()
        },

        toggleCounts() {
            let chartType = this.data.config[this.type].chart.type
            let labelEnabled = this.data.config[this.type].plotOptions[chartType].dataLabels.enabled

            this.$emit('change', 'countToggle', !labelEnabled)

            setTimeout(() => {
                this.toggleEnabled = !labelEnabled
            }, 500)
        },
    }
}
</script>

<style scoped lang="scss">
.chart-container {
    background: #fff;
    min-height: 523px;

    .demographic-select {
        width: 140px;

        ::v-deep .v-input__slot {
            border: none;
        }
    }

    .field-area {
        height: 56px;
        padding-top: 10px;
    }

    .chart-area {
        &.expanded-printable {
            display: none;
        }

        .ec-chart {
            height: 100%;

            ::v-deep * {
                font-family: 'CerebriSans-Regular', Cerebri Sans, 'Roboto', sans-serif !important;
            }

            ::v-deep .highcharts-container {
                width: 100% !important;
            }

            &-menu {
                &-btn {
                    position: relative;
                    top: -400px;
                    z-index: 1;
                    float: right;
                    color: #7E8494 !important;
                }
            }

            &-no-data-title {
                white-space: normal;
                margin-left: 0px;
                margin-top: 0px;
                color: rgb(126, 132, 148);
                fill: rgb(51, 51, 51);
                border-bottom: 1px solid rgb(224, 225, 230);
                display: block;
                width: 100% !important;
                left: 0 !important;
                padding: 9px 0 16px 16px !important;
                font-size: 14px !important;

                &-info {
                    display: flex;
                    justify-content: center;
                    padding-top: 10px;
                }
            }
        }
    }
}

.placeholder-text {
    width: 160px;
    z-index: 1;

    ::v-deep .v-input.fe-date-picker-text-field input::placeholder {
        color: grey !important;
        opacity: 1 !important;
    }
}

.spinner {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    line-height: 250px !important;
}
</style>

<template>
    <div>
        <fe-mask v-show="loading" :showLoader="true" />

        <v-layout v-if="!emptyCharts" row wrap class="ec-collection-layout-main">
            <template v-for="(chart, index) in totalCharts">
                <v-flex md6 xs12 :style="chart.type === 'big5' ? 'margin-bottom: -30px;' : null" :key="index" v-observe-visibility="{callback: (isVisible, entry) =>
                    visibilityChanged(isVisible, entry, index), once: true, throttle: 300}" :ref="'chart' + index" v-resize="resize">
                    <template v-if="chart && chart.overallResults">
                        <template v-for="(cfg, i) in chart.overallResults">
                            <v-lazy>
                                <benchmark-chart
                                    ref="collectionChartRef"
                                    :border="false"
                                    class="ma-1"
                                    :uiConfig="chart.uiConfig"
                                    collectionChart
                                    :currentCategory="{ id:  1 }" :config='cfg' :type="cfg.BASIC.length > 0 ? 'basic' : 'detailed'" :key="`basic-chart-` + i"
                                    @delete="removePinnedChart(chart.params.saved_search_id)"
                                    :pinTitle.sync="chart.title"
                                    :pinnable="false"
                                    :performanceBands="performanceBands"
                                    @bandNotFound="fetchPerformanceBands(true)"
                                />
                                <!-- <stacked-chart :config="cfg" :type="cfg.BASIC.length > 0 ? 'basic' : 'detailed'"
                                   :border="false" @all-charts="getAllCharts" collectionChart></stacked-chart> -->
                            </v-lazy>
                        </template>
                    </template>
                    <template v-else-if="chart && chart.chartType === 'attendance'">
                        <v-lazy>
                            <attendance-chart
                                :pinTitle="chart.search_id.title || chart.name"
                                :height="400"
                                :config="chart.config"
                                :chartParams="chart.chartParams"
                                :property="chart.property"
                                @all-charts="getAllCharts"
                                :showInsight="false"
                                :params="{saved_search_id: chart.search_id.saved_search_id, dashboard_id: chart.search_id.dashboard_id, index:  chart.index}"
                                :pinnable="false"
                                collectionChart
                                @chartClicked="attendanceClick(chart, ...arguments)"
                            />
                        </v-lazy>
                    </template>
                    <template v-else-if="chart && chart.type === 'big5'">
                        <v-lazy>
                            <big-five-chart
                                :height="400"
                                :config="chart"
                                :chartParams="chart.chartParams"
                                :categories="chart.categories"
                                :pinTitle="chart.name"
                                @all-charts="getAllCharts"
                                collectionChart
                                @chartClicked="bigFiveClick(chart, ...arguments)"
                            />
                        </v-lazy>
                    </template>
                    <template v-else-if="chart && (chart.type === 'pyramid' || chart.type==='detailed_pyramid')">
                        <v-lazy>
                            <incident-range-charts
                                :params="{saved_search_id: chart.search_id.saved_search_id}"
                                :chartTypes="[chart.type]"
                                :pinnedChart="chart"
                                collectionChart
                                @all-charts="getAllCharts"
                            />
                        </v-lazy>
                    </template>
                    <template v-else-if="chart && chart.chartType === 'studentScore'">
                        <v-lazy>
                            <assessment-trendline :border="false" :params="{saved_search_id: chart.search_id.saved_search_id, dashboard_id: chart.search_id.dashboard_id, index:  chart.index,
                                type: chart.chartType, title: chart.search_id.title}" :pinnable="false" :height="350" @all-charts="getAllCharts" collectionChart :isCollection="true"></assessment-trendline>
                        </v-lazy>
                    </template>
                    <template v-else-if="chart && chart.chartType === 'academicMonitor'">
                        <v-lazy>
                            <academic-progress-monitoring2
                                :pinTitle="chart.name"
                                :params="{saved_search_id: chart.search_id.saved_search_id, dashboard_id: chart.search_id.dashboard_id, index:  chart.index}"
                                :height="350"
                                collectionChart
                                @all-charts="getAllCharts"
                                :isCollection="true"
                            />
                        </v-lazy>
                    </template>
                    <template v-else-if="chart && chart.chartType === 'behaviorMonitor'">
                        <v-lazy>
                            <behavior-progress-monitoring
                                :pinTitle="chart.name"
                                :params="{saved_search_id: chart.search_id.saved_search_id, dashboard_id: chart.search_id.dashboard_id, index:  chart.index}"
                                style="height: 400px;"
                                height="300"
                                collectionChart
                                @all-charts="getAllCharts"
                                @delete="removePinnedChart(chart.search_id.saved_search_id)"
                            />
                        </v-lazy>
                    </template>
                    <template v-else-if="chart && chart.chartType === 'longitudinal'">
                        <v-lazy>
                            <div class="ml-2 mt-1" style="width: 98%;">
                                <chart-panel border class="chart-container" style="height: 405px;">
                                    <div class="longitudinal-chart">
                                        <highcharts v-bind="chart.config" class="fe-chart"/>
                                        <div class="collection-tools d-flex">
                                            <fe-icon-btn useIcon="fas fa-expand-alt" @click="doExpand(chart)"/>
                                            <fe-icon-btn useIcon="far fa-trash" @click="removePinnedChart(chart.search_id)"/>
                                            <menu-btn class="pa-3">
                                                <v-list-item @click="doRename(chart)">
                                                    <i class="far fa-edit fe-icon print-icon"/>
                                                    <span class="ml-6">Rename Pin</span>
                                                </v-list-item>
                                            </menu-btn>
                                        </div>
                                    </div>
                                </chart-panel>
                            </div>
                        </v-lazy>
                    </template>
                    <template v-else>
                        <chart :config="chart" :downloadable="false" :printable="false" collectionChart class="mr-2"></chart>
                    </template>
                </v-flex>
            </template>
        </v-layout>

        <v-layout v-else align-center justify-center column fill-height text-center class="empty-state">
            <fe-empty-state
                image="/images/rocket_transparent.png"
                text="You have not pinned any Charts yet. <br />
                To get started, navigate to any Chart and click the Pin Icon."
            />
        </v-layout>

        <fe-dialog
            v-if="renameDialog.show"
            title="Rename Chart"
            acceptButtonText="Apply"
            dismissButtonText="Cancel"
            persistent
            @accept="renamePin()"
            @dismiss="renameDialog.show = false"
        >
            <v-layout row>
                <div class="d-flex align-center">
                    <span>Rename:</span>
                    <fe-text-field v-model="renameDialog.name" class="ml-2" autofocus/>
                </div>
            </v-layout>
        </fe-dialog>
    </div>
</template>

<script>
    import Vue from 'vue';
    import Highcharts from 'highcharts'
    import BigFive from "../../incidents/BigFive";
    import StackedChart from "../../../charts/StackedChart";
    import BigFiveChart from "../../../charts/BigFive";
    import AttendanceChart from "../../../charts/AttendanceChart";
    import AssessmentTrendline from "../../../charts/AssessmentTrendline";
    import AcademicProgressMonitoring from "../../../charts/AcademicProgressMonitoring";
    import AcademicProgressMonitoring2 from "../../../charts/AcademicProgressMonitoring2";
    import BehaviorProgressMonitoring from "../../../charts/BehaviorProgressMonitoring";
    import draggable from 'vuedraggable';
    import exports from 'highcharts/modules/exporting';
    import Chart from "../../../charts/Chart";
    import IncidentRangeCharts from "../../incidents/IncidentRange";
    import BenchmarkChart from '@/components/charts/BenchmarkChart'
    import MenuBtn from '@/components/common/button/MenuBtn'
    import ChartPanel from '@/components/charts/ChartPanel'
    exports(Highcharts)

    export default {
        name: 'CollectionCharts',
        components: {
            IncidentRangeCharts,
            Chart,
            AcademicProgressMonitoring2,
            AcademicProgressMonitoring,
            BehaviorProgressMonitoring,
            AssessmentTrendline,
            AttendanceChart,
            BigFiveChart,
            StackedChart,
            BigFive,
            BenchmarkChart,
            MenuBtn,
            ChartPanel,
            draggable
        },
        props: {
            reload: { type: Boolean, default: false }
        },
        data() {
            return {
                loading: false,
                activeItem: { name: 'Charts' },
                collectionData: [],
                collectionManageSelection: null,
                overallResults: [],
                categories: [],
                currentCategory: {},
                totalCharts: [],
                emptyCharts: false,
                collectionUsers: [],
                charts: [],
                localCollectionSearches: [],
                dashboard_id: null,
                noDataChart: null,
                loadedCategories: [],
                collectionChartData: {},
                allCollections: [],
                performanceBands: [],
                isFetchingPerformanceBands: false,
                renameDialog: {
                    show: false,
                    existing: '',
                    name: ''
                }
            }
        },
        watch: {
            collectionData(v) {
                this.emptyCharts = false
                this.localCollectionSearches = []
                if(v && v.length > 0) {
                    v.forEach(ss => {
                        if(ss.dashboard_saved_search_type_id !== 6) {
                            this.localCollectionSearches.push(ss)
                        }
                    })
                }
                this.categories = []
                this.totalCharts = []
                if(v && this.localCollectionSearches.length > 0) {
                    if(this.localCollectionSearches.length > 0) {
                        this.localCollectionSearches.forEach((lcs,index) => {
                            this.totalCharts.push({})
                            if(this.reload) {
                                this.visibilityChanged(true, null, index)
                            }
                        })
                    }
                } else {
                    this.emptyCharts = true
                }

                if(this.reload) {
                    this.$emit('reloaded', false)
                }
            },
            $route(to, from) {
                this.onLoad()
            },
            reload(v) {
                if(v) {
                    this.onLoad()
                }
            },
            reorderRowData(v) {
                this.$emit('reorder-row-data', v)
            },
        },
        mounted() {
            this.onLoad()
            this.fetchPerformanceBands()
        },
        methods: {
            async fetchPerformanceBands(isResync = false) {
                // If this is to resync missing PBs, then there might be a ton of
                // charts with the same problem (e.g. 29 charts on district search).
                // As long as one of their emits gets processed, we should hope/expect
                // all of them will be brought up-to-date.
                if (isResync && this.isFetchingPerformanceBands) {
                    return
                } else if (isResync) {
                    console.warn('Performance Band not found, possibly out of sync; requesting new bands data...')
                }

                this.isFetchingPerformanceBands = true
                this.performanceBands = await this.$modelGet('userTargetSet')
                this.isFetchingPerformanceBands = false
            },
            attendanceClick (chart, key, rec, evt) {
                let p = {
                    ...chart.config.params,
                    ...rec.params
                }
                delete p.chart_type
                delete p.saved_search_id

                this.$store.commit('global/addDockableWindow', {
                    name: chart.name,
                    component: 'attendance-reporting',
                    attrs: {
                        params: p
                    }
                })
            },
            bigFiveClick (chart, key, rec, evt, modParams) {
                let p = { ...chart.chartParams }
                p[chart.config.param_key] = rec[chart.config.param_key]
                p.student_id = rec.student_id || chart.chartParams.student_id
                p.school_year_id = rec.school_year_id || modParams.school_year_id
                if (modParams.range) p.range = modParams.range
                if (modParams.start_date) p.start_date = modParams.start_date
                if (modParams.end_date) p.end_date = modParams.end_date
                delete p.saved_search_id
                delete p.chart_types

                this.$store.commit('global/addDockableWindow', {
                    name: 'Incident Drilldown',
                    component: 'incident-overview',
                    attrs: {
                        name: rec[key],
                        params: p,
                        allCharts: true,
                        fixedTools: false,
                        breadcrumbs: true
                    }
                })
            },
            onLoad() {
                this.collectionData = []
                this.totalCharts = []
                this.loadedCategories = []
                if(this.$route.params.id) {
                    this.dashboard_id = parseInt(this.$route.params.id.split(':')[1])
                    this.$axios.get('dashboard.php?action=get&property=saved_search&dashboard_id=' + this.dashboard_id)
                        .then(response => {
                            this.collectionData = response.data.saved_searches
                        })
                }
            },
            collectionChart(ss, index) {
                this.collectionChartData = {}

                switch(ss.dashboard_saved_search_type_id) {
                    case 1:
                        this.buildStackedChart(ss, index)
                        break;
                    case 2:
                        Vue.set(this.totalCharts, index, {search_id: ss, index: index, chartType: 'studentScore'})
                        break;
                    case 3:
                        this.buildBig5(ss, index)
                        break;
                    case 4:
                        Vue.set(this.totalCharts, index, {name: ss.title, search_id: ss, index: index, chartType: 'academicMonitor'})
                        break;
                    case 5:
                        Vue.set(this.totalCharts, index, {name: ss.title, search_id: ss, index: index, chartType: 'behaviorMonitor'})
                        break;
                    case 7:
                    case 8:
                        this.buildAttendance(ss, index)
                        break;
                    case 9:
                    case 10:
                        this.buildIncidentRange(ss, index)
                        break;
                    case 11:
                        this.buildLongitudinal(ss, index)
                        break;
                }
            },
            removePinnedChart(savedSearchId) {
                let me = this

                this.$confirmDelete([1], () => {
                    let params = {
                        dashboard_id: me.dashboard_id,
                        saved_search_id: savedSearchId
                    }
                    me.$axios.post('dashboard.php?action=delete&property=saved_search', { dashboard_saved_search: params })
                        .then(response => {
                            if(response.data.success) {
                                me.$snackbars.$emit('new', { text: response.data.msg, usage: 'success' })
                                me.$store.commit('collection/set', {
                                    key: 'reloadCollection',
                                    value: true
                                })
                            } else {
                                me.$snackbars.$emit('new', { text: response.data.msg, usage: 'warning' })
                            }
                        })
                }, null, 'Are you sure you want to remove this pinned chart?')

            },
            doExpand(chart) {
                let cfg = this.$_.cloneDeep(chart.config.options)
                cfg.chart.height = window.innerHeight-150

                this.$dockableWindow({
                    name: cfg.title.text,
                    component: 'highcharts',
                    attrs: {
                        options: cfg,
                        class: 'ma-5',
                    }
                })
            },
            doRename(existing) {
                this.renameDialog.name = existing.name
                this.renameDialog.existing = existing
                this.renameDialog.show = true
            },
            renamePin() {
                let newName = this.renameDialog.name
                let oldName = this.renameDialog.existing.config.options.title.text
                if (!this.$_.isEmpty(newName) && newName !== oldName) {
                    let params = {
                        dashboard_id: this.dashboard_id,
                        saved_search_id: this.renameDialog.existing.search_id,
                        rank: this.renameDialog.existing.index,
                        title: newName
                    }

                    let url = 'dashboard.php?action=update&property=saved_search'

                    this.$axios.post(url, {saved_searches: [params]}).then(response => {
                        if (response.data.success) {
                            this.$snackbars.$emit('new', {text: response.data.msg, usage: 'success'})
                            this.$store.commit('collection/set', {
                                key: 'reloadCollection',
                                value: true
                            })
                        } else {
                            this.$snackbars.$emit('new', {text: response.data.msg, usage: 'warning'})
                        }
                    })
                }
                this.renameDialog.show = false
            },
            buildAttendance(ss, index) {
                let type = ss.dashboard_saved_search_type_id === 7 ? 'charts' : 'period_charts'

                this.$data.overallResults = []
                this.$data.categories = []

                this.$axios.get('attendance.php?action=get&property=' + type + '&saved_search_id=' + ss.saved_search_id)
                    .then(response => {
                        response.data.charts.forEach((chart) => {
                            let chartParams = chart.data.params
                            Vue.set(this.totalCharts, index, {
                                name: chart.title,
                                config: chart,
                                chartParams: chartParams,
                                property: type,
                                chartType: 'attendance',
                                index: index,
                                search_id: ss
                            })
                        })
                    })
            },
            getTitle(ss) {
                let rec = this.localCollectionSearches.find(s => s.saved_search_id == ss.saved_search_id) || null
                return rec?.title
            },
            buildStackedChart(ss, index) {
                this.$data.overallResults = []

                this.$axios.get('targetScores.php?action=get&property=categories&saved_search_id=' + ss.saved_search_id)
                    .then(response => {
                        this.$data.categories = []
                        if(response.data.categories.length === 0) {
                            Vue.set(this.totalCharts, index, {
                                chartType: 'no data',
                                index: index,
                                search_id: ss,
                                saved_search_id: ss.saved_search_id,
                                dashboard_id: ss.dashboard_id,
                                chart: 'no-data',
                                savedSearchRecord: this.collectionData.find(r=>r.saved_search_id===ss.saved_search_id)
                            })
                        } else {
                            response.data.categories.forEach(cat => {
                                this.$data.categories.push({
                                    id: cat.category_id,
                                    name: cat.category_name,
                                    title: ss.title,
                                    isLoaded: false,
                                    params: {saved_search_id: ss.saved_search_id},
                                    overallResults: [],
                                    assessments: [],
                                    showDataWall: false,
                                    index: index,
                                    dash_id: ss.id,
                                    savedSearchRecord: this.collectionData.find(r=>r.saved_search_id===ss.saved_search_id)
                                })
                            })
                            if (this.$data.categories.length > 0) {
                                this.loadCategory(this.$data.categories[0], ss, index)
                            }
                        }
                    })
            },
            buildIncidentRange(ss, index) {
                Vue.set(this.totalCharts, index, {
                    name: ss.title,
                    config: ss,
                    type: ss.dashboard_saved_search_type_id == 10 ? 'detailed_pyramid' : 'pyramid',
                    chartType: ss.dashboard_saved_search_type_id == 10 ? 'detailed_pyramid' : 'pyramid',
                    index: index,
                    search_id: ss
                })
            },
            buildBig5(ss, index) {
                this.$axios.get('behaviorIncident.php?action=get&property=big5&saved_search_id=' + ss.saved_search_id)
                    .then(response => {
                        let chartParams = {
                            ...response.data.incidents.params,
                            chart_types: response.data.incidents.charts[0].chart_type
                        }
                        response.data.incidents.charts.forEach((chart) => {
                            let categories = chart.fields ? chart.fields : []
                            chart.title = ss.title
                            Vue.set(this.totalCharts, index, {
                                name: chart.title,
                                config: chart,
                                chartParams: chartParams,
                                categories: categories,
                                type: chart.chart_type.indexOf('pyramid') > -1 ? chart.chart_type : 'big5',
                                chartType: response.data.incidents.charts[0].chart_type,
                                index: index,
                                baseParams: chartParams,
                                search_id: ss,
                                restricted: response.data.incidents.restricted
                            })
                        })
                    })
            },
            buildLongitudinal(ss, index) {
                let config = JSON.parse(ss.config_text)
                config.options.title = {
                    text: ss.title,
                    useHTML: true,
                    align: "left",
                    style: {
                        'color': '#7E8494',
                        'border-bottom': '1px solid #E0E1E6',
                        'display': 'block',
                        'width': '100%'
                    }
                }
                Vue.set(this.totalCharts, index, {
                    name: ss.title,
                    config: config,
                    chartType: 'longitudinal',
                    index: index,
                    search_id: ss.saved_search_id
                })
            },
            loadCategory(category, ss, index) {
                this.currentCategory = category
                category.params.category_id = category.id
                this.$axios.get('targetScores.php?action=get&' + this.$objectToParams(category.params))
                    .then(response => {
                        category.overallResults = response.data.categories[0].stacked_charts
                        category.overallResults.forEach(assess => {
                            assess.title = category.title ? category.title : assess.sub_category_name
                            assess.search_id = ss
                        })
                        category.assessments = [...new Set(category.overallResults.map(item => item.data_point_type_name))]
                    })
                    .finally(() => {
                        this.$emit('updated-category', category)
                        let idx = this.$data.loadedCategories.findIndex(c => c.index === index)
                        if(idx === -1) {
                            let ssRec = this.collectionData.find(r=>r.saved_search_id===ss.saved_search_id)

                            Vue.set(this.totalCharts, index, {
                                id: category.category_id,
                                name: category.category_name,
                                title: ss.title,
                                isLoaded: false,
                                params: {saved_search_id: ss.saved_search_id},
                                overallResults: category.overallResults,
                                assessments: category.assessments,
                                showDataWall: false,
                                index: index,
                                dash_id: ss.id,
                                uiConfig: ssRec.config_text ? JSON.parse(ssRec.config_text) : {}
                            })
                            this.$data.loadedCategories.push({ index: index })
                        }
                    })
            },
            getAllCharts(v) {
                if(v) {
                    if(v.$refs.feChart) {
                        this.charts.push(v.$refs.feChart.chart)
                        this.$emit('all-charts', this.charts)
                    }
                }
            },
            visibilityChanged (isVisible, entry, index) {
                if(isVisible) {
                    this.collectionChart(this.localCollectionSearches[index], index)
                }
            },
            resize(v) {
                if (this.$refs.collectionChartRef) {
                    this.$refs.collectionChartRef.forEach(chart => {
                        chart.disableTooltip()
                    })
                }
            },
        }
    }
</script>

<style lang="scss" scoped>
    .ec-collection {
        &-left-layout {
            ::v-deep .menu-title-toolbar {
                display: none !important;
            }
            ::v-deep  .nav-item-selected {
                border-top-left-radius: 4px;
                border-bottom-left-radius: 4px;
            }
            ::v-deep {
                .flex-grow-1 {
                    border-top: 1px solid #E0E1E6;
                }
            }
        }
        &-layout-main {
            height: calc(100vh - 200px);
            overflow: auto;
        }
        &-empty-state {
            justify-content: center;
            display: flex;
            text-align: center;
        }
        &-card {
            min-height: 100px;
            max-height: 100px;
            line-height: 0;
            position: relative;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-flex: 1;
            -ms-flex: 1 1 auto;
            flex: 1 1 auto;
            min-width: 0;
            &-text {
                padding: 4px !important;
                text-align: center;
                &-text {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    max-width: 90px;
                    width: 90px;
                    white-space: nowrap;
                    color: #fff !important;
                    display: block;
                    position: relative;
                }
            }
        }
        &-reorder-list {
            cursor: pointer;
            border: 1px solid #000;
            border-radius: 4px;
            margin-bottom: 2px;
            height: 35px;
            min-height: 35px !important;
            display: flex;
            align-items: center;
        }
    }

    .longitudinal-chart {
        .fe-chart {
            ::v-deep .highcharts-title {
                width: 100% !important;
                left: 0 !important;
                padding: 0 0 10px 16px !important;
                font-size: 14px !important;
            }

            ::v-deep * {
                font-family: 'CerebriSans-Regular', Cerebri Sans, 'Roboto', sans-serif !important;
            }
        }

        .collection-tools {
            position: relative;
            top: -405px !important;
            float: right  !important;
            z-index: 1 !important;
        }
    }

    ::v-deep .empty-state img {
        height: 340px;
    }
</style>

<template>
    <div class="d-flex flex-fill flex-column pa-4" id="enrollment-overlay-screen" style="overflow: hidden; display:flex; flex-direction: column;">
        <div class="d-flex" style="padding-left: 4px !important">
            <fe-filter-btn
                v-model="searches.school_id"
                ref="school-filter-menu"
                rootProperty="schools"
                title="Schools"
                :url="schoolUrl"
                :closeOnSelect="false"
                multiple
                showFirstSelection
                showClearButton
                showApplyButton
                @clear="clearFilter('school')"
                @apply="apply('school-filter-menu')"
            />

            <fe-filter-btn
                v-model="searches.grade_id"
                ref="grade-filter-menu"
                rootProperty="grades"
                title="Grade Levels"
                :url="gradeUrl"
                :closeOnSelect="false"
                multiple
                showFirstSelection
                showClearButton
                showApplyButton
                @clear="clearFilter('grade')"
                @apply="apply('grade-filter-menu')"
            />

            <fe-filter-btn
                v-model="searches.ethnicity"
                ref="ethnicity-filter-menu"
                rootProperty="ethnicities"
                itemValue="ethnicity"
                itemText="display_name"
                title="Ethnicities"
                :url="ethnicityUrl"
                :closeOnSelect="false"
                :itemProcessor="itemProcessor"
                multiple
                showFirstSelection
                showClearButton
                showApplyButton
                @clear="clearFilter('ethnicity')"
                @apply="apply('ethnicity-filter-menu')"
            />

            <fe-filter-btn
                v-model="searches.gender"
                ref="gender-filter-menu"
                rootProperty="genders"
                itemValue="gender"
                itemText="display_name"
                title="Genders"
                :url="genderUrl"
                :closeOnSelect="false"
                multiple
                showFirstSelection
                showClearButton
                showApplyButton
                @clear="clearFilter('gender')"
                @apply="apply('gender-filter-menu')"
            />

            <more-filters v-if="canSeeCustomDemos" ref="enrollment-more-filters" v-model="filters" @apply="applyMore"/>
        </div>

        <div v-if="filterChips.length">
            <v-flex grow style="padding-left: 8px !important">
                <template v-for="chip in filterChips">
                    <fe-chip ref="chips" close @click:close="removeChipFilter(chip)">
                        {{ chip.displayName }}
                    </fe-chip>
                </template>
                <v-btn class="label ml-2" text @click="clearAllFilters">Clear Filters</v-btn>
            </v-flex>
        </div>

        <fe-tabs
            class="enrollment-tabs"
            style="height: 65px"
            :tabs="tabs"
            @tabClicked="tabClicked"
        />

        <div
            v-if="activeTab.show === 'demographic'"
            style="padding-left: 15px;"
            class="remote-dem-combo"
        >
            <div style="padding-top: 8px">
                <div style="float: left" class="center-demo-combo-title">Explore by:</div>
                <fe-remote-combo
                    v-model="selectedDemographic"
                    :items="demographicOptions"
                    :clearable="false"
                    hide-details
                    @change="chartParamChange('demographic', $event)"
                    class="demographic-select mt-1 mb-0"
                />
            </div>
        </div>

        <div v-show="showAlert" class="ma-3 large-data-alert">
            <div style="font-size: 16px;">
                That's a lot of data! To see less data in these charts, filter to specific schools. You can also
                download a CSV to view the data in a spreadsheet.
            </div>

            <v-btn class="right-align" id="close" fab small icon @click="closeAlert">
                <v-icon size="20">close</v-icon>
            </v-btn>
        </div>

        <div
            v-if="activeTab.show === tab.show"
            v-for="(tab, i) in tabs"
            class="chart-area row"
            :key="`tab-${i}`"
        >
            <no-school-groups
                v-if="noSchoolGroups"
                :canManageSchools="canManageSchools"
                :windowId="dockableWindowId"
            />

            <no-filter-results
                v-else-if="noFilterResults  && loaded"
                @clearFilters="clearAllFilters"
            />

            <div v-else class="charts d-flex">
                <div class="col-6 ongoing">
                    <enrollment-chart
                        title="Ongoing Enrollment"
                        :data="tab"
                        :demographic="getDemoName()"
                        :date="selectedDate"
                        :toggle="countsToggle"
                        :ready="tabData[tab.show].ongoing.loaded"
                        :error="tabData[tab.show].error"
                        @change="chartParamChange"
                    />
                </div>

                <div class="col-6 trend">
                    <enrollment-chart
                        title="Enrollment Trend"
                        type="trend"
                        :data="tab"
                        :demographic="getDemoName()"
                        :year="selectedTimePeriod"
                        :ready="tabData[tab.show].trend.loaded"
                        @change="chartParamChange"
                    />
                </div>
            </div>
        </div>

        <fe-crud ref="crud" :config="crudConfig"/>
    </div>
</template>

<script>
import {mapState} from "vuex";
import EnrollmentChart from "@/components/charts/EnrollmentChart.vue";
import MoreFilters from "./MoreFilters.vue";
import NoSchoolGroups from "./messaging/NoSchoolGroups.vue"
import NoFilterResults from "./messaging/NoFilterResults.vue";

export default {
    name: 'EnrollmentReporting',

    components: {
        EnrollmentChart,
        MoreFilters,
        NoSchoolGroups,
        NoFilterResults
    },

    props: {
        params: {
            type: Object
        }
    },

    data() {
        return {
            activeTab: {
                name: 'Overall',
                show: 'district',
                path: '#'
            },
            tabData: {
                district: {ongoing: {data: undefined, loaded: false, error: false}, trend: {data: undefined, loaded: false, error: false}},
                school: {ongoing: {data: undefined, limited: false, loaded: false, error: false}, trend: {data: undefined, loaded: false, error: false}},
                grade: {ongoing: {data: undefined, limited: false, loaded: false, error: false}, trend: {data: undefined, loaded: false, error: false}},
                demographic: {ongoing: {data: undefined, limited: false, loaded: false, error: false}, trend: {data: undefined, loaded: false, error: false}},
                school_group: {ongoing: {data: undefined, limited: false, loaded: false, error: false}, trend: {data: undefined, loaded: false, error: false}},
            },
            selectedDemographic: 'ethnicity',
            demographicOptions: [{
                name: 'Race/Ethnicity',
                id: 'ethnicity'
            }, {
                name: 'Gender',
                id: 'gender'
            }, {
                name: 'Meal Status',
                id: 'meal_status'
            }, {
                name: 'Disability',
                id: 'disability'
            }, {
                name: 'ELP Level',
                id: 'ell_flag'
            }],
            selectedDate: this.$dayjs().format("MM/DD/YYYY"),
            selectedTimePeriod: {
                name: 'This Year',
                id: '0'
            },
            searches: {
                school_id: undefined,
                grade_id: undefined,
                ethnicity: undefined,
                gender: undefined
            },
            searchStateChange: false,
            filters: {},
            filterChips: [],
            noEnrollmentForSelectedDate: false,
            alertClosed: false,
            dataThreshold: 15,
            dockableWindowId: null,
            hasSchoolGroups: null,
            schools: '',
            canSeeCustomDemos: false,
            countsToggle: false,
        }
    },

    computed: {
        ...mapState('global', ['currentYear', 'sessionUser', 'defaultSchool']),

        loaded() {
            return this.tabData[this.activeTab.show].ongoing.loaded && this.tabData[this.activeTab.show].trend.loaded
        },

        tabs() {
            return [{
                name: 'Overall',
                show: 'district',
                config: this.districtConfig,
                path: '#',
                dataLimited: false
            }, {
                name: 'By School',
                show: 'school',
                config: this.schoolConfig,
                path: '#',
                dataLimited: false
            }, {
                name: 'By Grade Level',
                show: 'grade',
                config: this.gradeConfig,
                path: '#',
                dataLimited: false
            }, {
                name: 'By Demographic',
                show: 'demographic',
                config: this.demographicConfig,
                path: '#',
                dataLimited: false
            }, {
                name: 'By School Group',
                show: 'school_group',
                config: this.schoolGroupConfig,
                path: '#',
                dataLimited: false
            }]
        },

        schoolUrl() {
            return 'schools.php?action=get&school_year_id=' + this.currentYear.id
        },

        gradeUrl() {
            return 'grades.php?action=get&school_year_id=' + this.currentYear.id
        },

        ethnicityUrl() {
            return 'filters.php?action=get&filter=ethnicity'
        },

        genderUrl() {
            return 'filters.php?action=get&filter=gender'
        },

        districtConfig() {
            let ongoing = this.$_.cloneDeep(this.baseConfig)
            ongoing.chart.type = 'column'
            ongoing.exporting.filename = 'Ongoing Enrollment'
            ongoing.xAxis.title = 'School'

            if (this.tabData.district.ongoing.data) {
                ongoing.xAxis.categories = [this.schools]
                ongoing.series = [{
                    name: 'Enrolled Students',
                    data: this.tabData.district.ongoing.data.map(x => x.student_count)
                }]
            }

            let trend = this.$_.cloneDeep(this.baseConfig)
            trend.chart.type = 'line'
            trend.legend.enabled = true
            trend.exporting.filename = 'Enrollment Trend'
            trend.xAxis.title = 'Dates'

            if (this.tabData.district.trend.data) {
                let trendData = this.tabData.district.trend.data

                trend.xAxis.categories = trendData.map(x => x.end_date ? this.formattedDate(x.end_date) : x.name ? x.name : x.school_year_name)
                trend.series = [{data: trendData.map(x => x.enrolled_count), name: this.schools}]
            }

            return {ongoing, trend}
        },

        schoolConfig() {
            let ongoing = this.$_.cloneDeep(this.baseConfig)
            let trend = this.$_.cloneDeep(this.baseConfig)

            if (this.tabData.school.ongoing.data) {
                let data = this.tabData.school.ongoing.data
                ongoing.exporting.filename = 'Ongoing Enrollment'
                ongoing.xAxis.title = 'School'

                if (data.length > this.dataThreshold) {
                    ongoing = this.addVerticalScroll(ongoing)
                    if (this.searchEmpty && this.filterEmpty) {
                        this.tabData.school.ongoing.limited = true
                    }
                }

                ongoing.xAxis.categories = data.map(x => x.school_name)
                ongoing.series = [{name: 'Enrolled Students', data: data.map(x => x.student_count)}]
            }

            trend.chart.type = 'line'
            trend.legend.enabled = true
            if (this.tabData.school.trend.data) {
                let trendData = this.tabData.school.trend.data
                trend.exporting.filename = 'Enrollment Trend'
                trend.xAxis.title = 'Dates'
                let categories = this.$_.uniq(trendData.map(x => x.end_date ? this.formattedDate(x.end_date) : x.name ? x.name : x.school_year_name))
                trend.xAxis.categories = categories
                trend.series = this.buildTrendSeries(trendData, 'school_name', categories)
            }

            return {ongoing, trend}
        },

        gradeConfig() {
            let ongoing = this.$_.cloneDeep(this.baseConfig)
            let trend = this.$_.cloneDeep(this.baseConfig)

            if (this.tabData.grade.ongoing.data) {
                let data = this.tabData.grade.ongoing.data
                ongoing.exporting.filename = 'Ongoing Enrollment'
                ongoing.xAxis.title = 'School'

                if (data.length > this.dataThreshold) {
                    ongoing = this.addVerticalScroll(ongoing)
                    if (this.searchEmpty && this.filterEmpty) {
                        this.tabData.grade.ongoing.limited = true
                    }
                }

                ongoing.xAxis.categories = data.map(x => x.grade_name)
                ongoing.series = [{name: 'Enrolled Students', data: data.map(x => x.student_count)}]
            }

            trend.chart.type = 'line'
            trend.legend.enabled = true
            if (this.tabData.grade.trend.data) {
                let trendData = this.tabData.grade.trend.data
                trend.exporting.filename = 'Enrollment Trend'
                trend.xAxis.title = 'Dates'
                let categories = this.$_.uniq(trendData.map(x => x.end_date ? this.formattedDate(x.end_date) : x.name ? x.name : x.school_year_name))
                trend.xAxis.categories = categories
                trend.series = this.buildTrendSeries(trendData, 'grade_name', categories)
            }

            return {ongoing, trend}
        },

        demographicConfig() {
            let ongoing = this.$_.cloneDeep(this.baseConfig)
            let trend = this.$_.cloneDeep(this.baseConfig)

            if (this.tabData.demographic.ongoing.data) {
                let data = this.tabData.demographic.ongoing.data
                ongoing.exporting.filename = 'Ongoing Enrollment'
                ongoing.xAxis.title = 'School'

                if (data.length > this.dataThreshold) {
                    ongoing = this.addVerticalScroll(ongoing)
                    if (this.searchEmpty && this.filterEmpty) {
                        this.tabData.demographic.ongoing.limited = true
                    }
                }

                ongoing.xAxis.categories = data.map(x => {
                    let name
                    if (x.ell_flag !== undefined) {
                        name = x.ell_flag.toString()
                    } else {
                        name = x[this.selectedDemographic]
                    }
                    return name ? name : 'N/A'
                })
                ongoing.series = [{name: 'Enrolled Students', data: data.map(x => x.student_count)}]
            }

            trend.chart.type = 'line'
            trend.legend.enabled = true
            if (this.tabData.demographic.trend.data) {
                let trendData = this.tabData.demographic.trend.data
                trend.exporting.filename = 'Enrollment Trend'
                trend.xAxis.title = 'Dates'
                let categories = this.$_.uniq(trendData.map(x => x.end_date ? this.formattedDate(x.end_date) : x.name ? x.name : x.school_year_name))
                trend.xAxis.categories = categories
                trend.series = this.buildTrendSeries(trendData, this.selectedDemographic == 'ell_flag' ? 'ell_level' : this.selectedDemographic, categories)
            }

            return {ongoing, trend}
        },

        schoolGroupConfig() {
            let ongoing = this.$_.cloneDeep(this.baseConfig)
            let trend = this.$_.cloneDeep(this.baseConfig)

            if (this.tabData.school_group.ongoing.data) {
                let data = this.tabData.school_group.ongoing.data
                ongoing.exporting.filename = 'Ongoing Enrollment'
                ongoing.xAxis.title = 'School'

                if (data.length > this.dataThreshold) {
                    ongoing = this.addVerticalScroll(ongoing)
                    if (this.searchEmpty && this.filterEmpty) {
                        this.tabData.school_group.ongoing.limited = true
                    }
                }

                ongoing.xAxis.categories = data.map(x => x.school_group)
                ongoing.series = [{name: 'Enrolled Students', data: data.map(x => x.student_count)}]
            }

            trend.chart.type = 'line'
            trend.legend.enabled = true
            if (this.tabData.school_group.trend.data) {
                let trendData = this.tabData.school_group.trend.data
                trend.exporting.filename = 'Enrollment Trend'
                trend.xAxis.title = 'Dates'
                let categories = this.$_.uniq(trendData.map(x => x.end_date ? this.formattedDate(x.end_date) : x.name ? x.name : x.school_year_name))
                trend.xAxis.categories = categories
                trend.series = this.buildTrendSeries(trendData, 'school_group_name', categories)
            }

            return {ongoing, trend}
        },

        baseConfig() {
            return {
                chart: {
                    type: 'bar'
                },
                credits: {
                    enabled: false
                },
                navigation: {
                    buttonOptions: {
                        enabled: false
                    }
                },
                title: {
                    enabled: false,
                    text: '',
                },
                exporting: {
                    filename: '',
                    csv: {
                        columnHeaderFormatter: function (item, key) {
                            if (item.isXAxis) {
                                return item.options.title
                            } else {
                                return item.name
                            }
                        }
                    },
                },
                xAxis: {
                    categories: ['Test'],
                    title: {
                        text: undefined
                    },
                },
                yAxis: {
                    title: {
                        text: '# of Students Enrolled'
                    },
                    labels: {
                        formatter: x => new Intl.NumberFormat().format(x.value)
                    }
                },
                legend: {
                    enabled: false,
                    align: 'center'
                },
                plotOptions: {
                    line: {
                        marker: {
                            enabled: false
                        }
                    },
                    bar: {
                        maxPointWidth: 50,
                        dataLabels: {
                            enabled: this.countsToggle
                        }
                    },
                    column: {
                        maxPointWidth: 125,
                        dataLabels: {
                            enabled: this.countsToggle,
                            inside: true,
                            style: {
                                textOutline: 0,
                                fontWeight: 600,
                                fontSize: 18
                            }
                        }
                    }
                },
                series: []
            }
        },

        searchEmpty() {
            return Object.values(this.searches).every(x => x === null || x === '' || x === undefined || x.included.length === 0)
        },

        filterEmpty() {
            return this.$_.isArray(this.filters) ? this.filters.length === 0 : Object.keys(this.filters).length === 0
        },

        noFilterResults() {
            let searchNum = this.searchEmpty ? 0 : Object.values(this.searches)
                .filter(x => x !== undefined)
                .map(search => search.included.length)
                .reduce((a, b) => a + b, 0)

            let totalFilters = searchNum + (this.filterEmpty ? 0 : this.$_.isArray(this.filters) ? this.filters.length : Object.keys(this.filters).length)
            let currentTab = this.tabData[this.activeTab.show]
            let ongoingData = currentTab.ongoing.data?.length ? currentTab.ongoing.data[0].student_count : null

            return (totalFilters !== 0 && (ongoingData === 0 || ongoingData === null) && (currentTab.trend.data?.length === 0 || !currentTab.trend.data?.length) && (!this.noEnrollmentForSelectedDate))
        },

        canManageSchools() {
            return this.$store.state.global.sessionUser?.security_codes?.includes("MANAGE_SCHOOLS")
        },

        noSchoolGroups() {
            return this.activeTab.show === 'school_group' && this.loaded && !this.hasSchoolGroups
        },

        crudConfig() {
            return this.$_.cloneDeep(this.$models.schoolGroups)
        },

        showAlert() {
            /** This line is to make this trigger when the data is loaded **/
            this.tabData[this.activeTab.show].ongoing.loaded
            return this.tabData[this.activeTab.show].ongoing.limited && !this.alertClosed
        },

        async activeDemos() {
            let demos = await this.$axios.get('crud.php?property=Demographic&action=get')
            return demos.data.demographic.filter(demo => (demo.can_search && demo.can_view) !== false).length > 0
        },
    },

    watch: {
        'searches.school_id': {
            handler(value, oldValue) {
                if (value !== oldValue) {
                    this.searchStateChange = true
                }
            }
        },
        'searches.grade_id': {
            handler(value, oldValue) {
                if (value !== oldValue) {
                    this.searchStateChange = true
                }
            }
        },
        'searches.ethnicity': {
            handler(value, oldValue) {
                if (value !== oldValue) {
                    this.searchStateChange = true
                }
            }
        },
        'searches.gender': {
            handler(value, oldValue) {
                if (value !== oldValue) {
                    this.searchStateChange = true
                }
            }
        },
    },

    async mounted() {
        if (this.defaultSchool) {
            this.searches.school_id = {excluded: [], included: [this.defaultSchool]}
            this.updateChips()
        }
        this.load()
        this.canSeeCustomDemos = await this.activeDemos
    },

    methods: {
        getDemoName() {
            return this.demographicOptions.find(demo => demo.id === this.selectedDemographic).name
        },

        requestParams() {
            let obj = {
                search: {
                    selected_date: this.selectedDate,
                    selected_time_period: this.selectedTimePeriod.id
                }
            }

            for (const search in this.searches) {
                let thisSearch = this.searches[search]

                if (thisSearch?.included?.length) {
                    let searchArray = []
                    thisSearch.included.forEach(x => {
                        if (x.hasOwnProperty('id')) {
                            searchArray.push(x.id)
                        } else if (x?.display_name_group?.length) {
                            searchArray.push(...x.display_name_group)
                        } else if (x.hasOwnProperty(search)) {
                            searchArray.push(x[search])
                        }
                    })
                    obj.search[search] = searchArray
                }
            }

            for (const filter in this.filters) {
                if (!obj.hasOwnProperty('filters')) {
                    obj.filters = []
                }

                obj.filters.push({
                    type: filter,
                    value: this.filters[filter]
                })
            }

            return obj
        },

        apply(filter, timeUpdate) {
            let chartChoice
            if (timeUpdate) {
                chartChoice = timeUpdate === 'date' ? 'ongoing' : 'trend'
                Object.entries(this.tabData).forEach(tab => tab[1][chartChoice] = {data: undefined, loaded: false, error: false})
                this.load(this.activeTab.show, chartChoice)
            } else {
                if (this.searchStateChange) {
                    this.searchStateChange = false
                    this.tabData = {
                        district: {ongoing: {data: undefined, loaded: false, error: false}, trend: {data: undefined, loaded: false, error: false}},
                        school: {ongoing: {data: undefined, loaded: false, error: false}, trend: {data: undefined, loaded: false, error: false}},
                        grade: {ongoing: {data: undefined, loaded: false, error: false}, trend: {data: undefined, loaded: false, error: false}},
                        demographic: {ongoing: {data: undefined, loaded: false, error: false}, trend: {data: undefined, loaded: false, error: false}},
                        school_group: {ongoing: {data: undefined, loaded: false, error: false}, trend: {data: undefined, loaded: false, error: false}}
                    }
                    this.load(this.activeTab.show)
                }
            }
            if (filter) {
                this.$refs[filter].openMenu = false
            }
            this.updateChips()
        },

        applyMore(filters) {
            this.filters = filters
            this.searchStateChange = true
            this.apply()
        },

        filterHasSelections(filterRef) {
            let currentFilter = this.$refs[filterRef]
            return currentFilter ? Object.values(currentFilter.included).length > 0 : false
        },

        noData() {
            let enrollmentCount = 0

            Object.entries(this.tabData).forEach(entry => {
                if (entry[1].ongoing.data !== undefined) enrollmentCount = enrollmentCount + entry[1].ongoing.data[0]?.student_count
                if (entry[1].trend.data !== undefined) enrollmentCount = enrollmentCount + entry[1].trend.data?.length
            })

            return enrollmentCount === 0 && this.searchEmpty && this.filterEmpty
        },

        load(group = 'district', singleChart) {
            let window = this.$store.getters['global/dockableWindows'].filter(w => w.component === 'enrollment-reporting').pop()
            this.dockableWindowId = window.dockedWindowId

            let countsUrl = this.$models.getUrl('enrollmentCounts', 'read')
            let trendsUrl = this.$models.getUrl('enrollmentTrends', 'read')

            let params = this.requestParams()

            switch (group) {
                case 'demographic':
                    params.group_by = this.selectedDemographic
                    break
                case 'district':
                    this.setSchoolLegend()
                    break
                default:
                    params.group_by = group
            }

            if (group === 'school_group') {
                this.$refs.crud.read()
                    .then(r => {
                        if (r.data.school_group_v.length) {
                            this.hasSchoolGroups = true
                        } else {
                            this.hasSchoolGroups = false
                            this.tabData[group].ongoing.loaded = true
                            this.tabData[group].trend.loaded = true
                        }
                    })
            }

            if (singleChart) {
                let url = countsUrl
                let dataName = 'enrollments'

                if (singleChart !== 'ongoing') url = trendsUrl, dataName = 'enrollment_trend'
                Promise.resolve(
                    this.getEnrollmentData(url, params, group, singleChart, dataName)
                ).then(() => {
                    this.tabData[group][singleChart].loaded = true
                    this.noEnrollmentForSelectedDate = this.noData()
                })
            } else {
                let countsData = this.getEnrollmentData(countsUrl, params, group, 'ongoing', 'enrollments')
                let trendsData = this.getEnrollmentData(trendsUrl, params, group, 'trend', 'enrollment_trend')

                return Promise.all([countsData, trendsData])
                    .then(() => {
                        this.tabData[group].ongoing.loaded = true
                        this.tabData[group].trend.loaded = true
                        this.noEnrollmentForSelectedDate = this.noData()
                    })
                }
        },

        getEnrollmentData(url, params, group, chart, dataName) {
            return this.$axios.post(url, params)
                .then(response => {
                    if (response) {
                        if (response.request.status !== 200) {
                            this.tabData[group][chart].error = true
                        }
                        let data = this.$ecResponse(response)
                        this.tabData[group][chart].data = data?.[dataName]
                    }
                })
        },

        tabClicked(tab) {
            this.activeTab = tab

            if (!this.tabData[tab.show].ongoing.loaded && !this.tabData[tab.show].trend.loaded) {
                this.load(tab.show)
            } else {
                this.apply(false, !this.tabData[tab.show].trend.loaded ? 'timePeriod' : 'date')
            }
        },

        chartParamChange(type, value) {
            switch (type) {
                case 'demographic':
                    this.selectedDemographic = value.id
                    this.tabData.demographic = {ongoing: {data: undefined, loaded: false, error: false}, trend: {data: undefined, loaded: false, error: false}}
                    this.load('demographic')
                    break;
                case 'date':
                    this.selectedDate = value
                    this.apply(false, 'date')
                    break;
                case 'timePeriod':
                    this.selectedTimePeriod = value
                    this.apply(false, 'timePeriod')
                    break;
                case 'countToggle':
                    this.countsToggle = value
                    break;
                default:
            }
        },

        buildTrendSeries(data, grouping, categories) {
            let series = {}
            data.forEach(x => {
                let name = x[grouping]
                let index = this.$_.indexOf(categories, x.name)
                if (!series.hasOwnProperty(name)) series[name] = {data: [], name}

                let seriesData = series[name].data
                if (seriesData.length < index) {
                    for (let i = seriesData.length; i < index; i++) {
                        seriesData.push(null)
                    }
                }
                seriesData.push(x.enrolled_count)
            })

            return Object.values(series)
        },

        updateChips() {
            let filters = []

            if (!this.searchesEmpty) {
                let activeSearches = Object.keys(this.searches).filter(key => this.searches[key] != null || this.searches[key] != '' && this.searches[key] != undefined)
                activeSearches.forEach(filterName => {
                    let search = this.searches[filterName]
                    let searchChip = {search: filterName, filters: [], displayName: ''}
                    search.included.forEach((activeFilter, i) => {
                        let keyValue = Object.entries(activeFilter).find(key => key.includes("name") ? key.includes("name") : key.includes("display_name"))
                        searchChip.filters.push({i: i, name: keyValue[1], id: activeFilter.id ? activeFilter.id : null})
                    })
                    searchChip.displayName = this.concatChipDisplayNames(searchChip)
                    if (searchChip.displayName !== "") filters.push(searchChip)
                })
            }

            if (!this.filterEmpty) {
                let activeFilters = this.$_.cloneDeep(this.filters)
                if (this.$_.isArray(activeFilters) && activeFilters.length > 0) {
                    activeFilters.forEach((filter, i) => {
                        let currentFilter = this.filters[filter]
                        filters.push(this.setAdvancedFilterChip(currentFilter, i))
                    })
                } else {
                    let i = 0
                    for (let currentObject of Object.entries(activeFilters)) {
                        filters.push(this.setAdvancedFilterChip(currentObject, i))
                        i++
                    }
                }
            }

            this.filterChips = filters
        },

        concatChipDisplayNames(chips) {
            let displayFilters
            if (chips.filters.length > 3) {
                displayFilters = `${chips.filters.filter(scf => scf.i < 3).map(df => df.name).join(', ')}, + ${chips.filters.length - 3} more`
            } else {
                displayFilters = chips.filters.map(scf => scf.name).join(', ')
            }

            return displayFilters
        },

        setAdvancedFilterChip(filter, i) {
            let demos = this.$store.getters['global/demographicsSearchable']
            let demoId = filter[0].split('_')[1]

            let currentDemo = demos.find(demo => demo.id === parseInt(demoId))
            let filterType = currentDemo['value_type']
            let filterChoices

            switch (filterType) {
                case 'boolean':
                    filterChoices = filter[1].includes('1') ? 'Yes' : 'No'
                    break
                case 'option':
                    let splitOptions = filter[1].split(',')
                    filterChoices = splitOptions.map(fOpt => currentDemo.options.find(opt => opt.id === parseInt(fOpt)).value)
                    filterChoices = filterChoices.length > 1 ? filterChoices.join(', ') : filterChoices
                    break
                case 'number':
                    let splitChoices = filter[1].split(':')
                    filterChoices = splitChoices.length > 2 ? `between ${splitChoices[1]} and ${splitChoices[2]}` : `${splitChoices[0]} ${splitChoices[1]}`
                    break
            }

            let keyValue = this.$_.isArray(filter) ? filter : Object.entries(filter)

            return {
                i: i,
                filter: filter,
                name: keyValue[0],
                displayName: `${currentDemo.name}: ${filterChoices}`
            }
        },

        removeChipFilter(filter, all = false) {
            let localSearches = this.$_.cloneDeep(this.searches)
            if (filter.search !== undefined) {
                delete localSearches[filter.search]
                this.searches[filter.search] = this.$_.pull(localSearches[filter.search], undefined)
            }

            let localFilters = this.$_.cloneDeep(this.filters)
            if (this.$_.isArray(localFilters) && localFilters.length > 0) {
                delete localFilters[filter]
                this.filters[filter] = this.$_.pull(localFilters[filter], undefined)
            } else {
                delete localFilters[filter.name]
            }
            this.searches = localSearches
            this.filters = localFilters
            if (!all) {
                this.updateChips()
                this.searchStateChange = true
                this.apply()
            }
        },

        clearFilter(filterRef) {
            let chipToClear = this.filterChips.find(chip => chip.search.includes(filterRef))
            this.$refs[`${filterRef}-filter-menu`].clearSelections()
            if (chipToClear !== undefined) {
                this.removeChipFilter(chipToClear)
            }
        },

        clearAllFilters() {
            this.searchStateChange = true
            this.searches = {school_id: undefined, grade_id: undefined, ethnicity: undefined, gender: undefined}
            if (this.filterChips.length) this.filterChips.forEach(chip => this.removeChipFilter(chip, true)), this.updateChips()
            this.apply()
        },

        itemProcessor(items) {
            return this.$_.uniqBy(items, 'display_name')
        },

        closeAlert() {
            this.alertClosed = true
        },

        addVerticalScroll(config) {
            config.xAxis.min = 0
            config.xAxis.max = this.dataThreshold
            config.xAxis.scrollbar = {
                enabled: true
            }

            return config
        },

        setSchoolLegend() {
            if (this.searches.school_id?.included) {
                let schools = this.searches.school_id.included
                if (schools.length === 1) this.schools = schools[0] ? schools[0].name : schools.name
                else this.schools = `Overall (${schools.length} Schools)`
            } else {
                Promise.resolve(
                    this.$axios.get(this.schoolUrl)
                ).then((response) => {
                    if (response && response.data) {
                        let schools = response.data.schools.map(school => school.name ? school.name : school.school_year_name)
                        if (schools.length === 1) {
                            this.schools = schools[0] ? schools[0] : schools
                        } else {
                            this.schools = `Overall (${schools.length} Schools)`
                        }
                    }
                })
            }
        },

        formattedDate(date) {
            return this.$dayjs(date).format('MMM YYYY')
        },
    }
}
</script>


<style scoped lang="scss">
.chart-area {
    position: relative;
    height: 100%;
    width: 100%;

    .charts {
        width: 100%;
    }
}

.demographic-select {
    max-width: 20%;
}

.center-demo-combo-title {
    font-weight: bold;
    font-size: 16px;
    padding-top: 8px;
    padding-right: 8px;
}

.enrollment-tabs {
    padding-left: 18px;

    ::v-deep .fe-tabs-default {
        padding-left: 0 !important
    }
    ::v-deep .fe-tabs a {
        padding-bottom: 15px;
    }
    ::v-deep .fe-tabs .v-tabs-slider {
        width: 100% !important;
        left: 0% !important;
    }
    ::v-deep .fe-tabs-default .v-tab {
        color: #495B8F !important;
        font-size: 14px;
    }
    ::v-deep .fe-tabs-default-color a {
        font-weight: 500 !important;
    }
    ::v-deep .fe-tabs-default .v-tab:hover {
        background-color: #ECEDF1;
    }
    ::v-deep .fe-tabs-default .v-tab--active {
        color: #006C90 !important;
    }
    ::v-deep .fe-tabs-default .v-tab--active:hover {
        background-color: #E5F0F4;
    }
}

.remote-dem-combo {
    ::v-deep .v-autocomplete:not(.v-input--is-disabled).v-select.v-text-field input {
        font-size: 16px;
        pointer-events: inherit;
    }
}

::v-deep .v-chip__content {
    max-width: none !important;
}

.label {
    color: var(--v-primary-base);
    cursor: pointer;
}

.label::before {
    background-color: transparent !important;
}

.filter-button-container {
    -webkit-box-align: center;
    align-items: center;
    display: flex;
    padding: 8px;
    margin: 0;
}

.filter-menu-open {
    ::v-deep .fe-filter-btn-btn {
        background: #E5F0F4 !important;
        border-color: transparent !important;
        color: #005673 !important;
    }
}

.filter-menu-closed {
    ::v-deep .fe-filter-btn-btn {
        color: #050F2D;
    }
}

.menu-apply {
    color: white;
    min-width: 25px;
    background: var(--v-primary-base) !important;
}

.large-data-alert {
    display: flex;
    align-items: center;
    padding: 12px 18px;
    border-left: 6px solid cornflowerblue;
    border-radius: 2px;
    background-color: #c4dcfa;
}

.right-align {
    margin-left: auto;
}

#close {
    float: right;
    padding: 2px 5px;
    height: 20px;
}

#csvDownload {
    cursor: pointer;
    text-decoration: underline;
    font-weight: bold
}

</style>

<template>
    <div
        class="flex-fill flex-column widget"
        :class="[`${classPrefix}${config.id}`, {'dash': !thumbnail}, {'drilldown': drilldown}]"
    >
        <div
            v-if="!thumbnail && !drilldown"
            ref="header"
            class="d-flex flex-shrink-1 widget-header"
            :class="`${classPrefix}${config.id}`"
        >
            <div class="d-flex pa-3">
                {{ config.name }}
                <fe-info-tip
                    v-if="config.description && !publicImage"
                    class="ml-2"
                    :tooltip="config.description"
                />
            </div>

            <v-spacer/>

            <fe-tooltip
                v-if="!publicImage && config.publicLink"
                :key="copied"
                :tooltip="copied ? 'Copied!' : 'Click to copy public image link'"
                direction="top"
            >
                <fe-icon-btn useIcon="fas fa-link" @click="copyLink"/>

                <v-text-field v-model="publicLink" ref="copyLink" class="hidden-link"/>
            </fe-tooltip>

            <ad-menu v-if="canModify && !publicImage" :items="visMenuItems" :returnValue="config" class="pr-2" dense/>
        </div>

        <div class="d-flex flex-fill widget-body" :class="`${classPrefix}${config.id}`" :style="dimensions">
            <div v-if="noData" class="no-data">
                <i class="fas fa-info-circle"/>

                <br>

                <ul>
                    <li v-for="item in noData">{{ item }}</li>
                </ul>
                <div v-if="lazy" v-intersect="onIntersect" style="width:0;height:0;"></div>
            </div>

            <highcharts
                v-if="show === 'chart'"
                ref='highchart'
                style="height: 100%; width: 100%;"
                :class="{'drillable': allowDrilldown && config.type !== 'googlesheet'}"
                :options="chartOptions"
            />

            <table-view
                v-if="show === 'table'"
                ref="table"
                :data="chartData"
                :config="config"
                :thumbnail="thumbnail"
                v-on="$listeners"
            />

            <numeric-view
                v-if="show === 'numeric'"
                :data="numericRecords"
                :config="config"
                :thumbnail="thumbnail"
                v-on="$listeners"
            />

            <fe-mask v-show="show === 'loading' && !drilldown && !thumbnail" :showLoader="true"/>
        </div>

        <div
            v-if="!thumbnail && config.goal"
            ref="footer"
            class="d-flex flex-shrink-1 widget-footer"
            :class="`${classPrefix}${config.id}`"
        >
            <i class="far fa-flag goal-flag"/>
            {{ config.goal.custom_statement || config.goal.default_statement }}
        </div>

        <v-menu
            v-if="!thumbnail"
            v-model="editType.show"
            :position-x="editType.x"
            :position-y="editType.y"
            closeOnClick
            absolute
            offset-y
        >
            <v-list class="chart-type-menu px-2" dense flat>
                <v-list-item
                    v-for="item in chartTypes"
                    :class="{divider: item.divider}"
                    @click="changeChartType(item.id)"
                >
                    <v-list-item-icon v-if="item.icon" class="menu-icon">
                        <v-icon>{{ item.icon }}</v-icon>
                    </v-list-item-icon>

                    <v-list-item-title :class="{remove: item.remove}">{{ item.name }}</v-list-item-title>

                    <v-spacer/>

                    <fe-info-tip v-if="item.description" :tooltip="item.description"/>

                    <i v-if="item.toggle" class="material-icons toggle-icon" :class="{on: config[item.property]}">
                        {{ config[item.property] ? 'toggle_on' : 'toggle_off' }}
                    </i>
                </v-list-item>
            </v-list>
        </v-menu>

        <fe-crud ref="crud" :config="crudConfig"/>
    </div>
</template>

<script>
import {mapState} from "vuex"
import AdMenu from "./Menu"
import Highcharts from 'highcharts'
import TableView from "./TableView"
import NumericView from "./NumericView"
import bullet from 'highcharts/modules/bullet'
import stockInit from 'highcharts/modules/stock'
stockInit(Highcharts)
bullet(Highcharts)

export default {
    name: "Visualization",

    components: {AdMenu, TableView, NumericView},

    props: {
        config: {
            type: Object,
            required: true
        },
        lazy: {
            type: Boolean,
            default: false,
        },
        dashboard: {
            type: Object,
            required: true
        },
        canModify: {
            type: Boolean,
            default: false
        },
        allowDrilldown: {
            type: Boolean,
            default: true
        },
        getSSValues: {
            type: Function,
            required: true
        },
        publicImage: {
            type: Boolean,
            default: false
        },
        thumbnail: {
            type: Boolean,
            default: false
        },
        drilldown: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            ready: false,
            lazyLoaded: false,
            lazyLoadReady: false,
            noData: false,
            editType: {
                show: false,
                x: undefined,
                y: undefined
            },
            bullets: [],
            goalDescriptors: [],
            chartData: undefined,
            numericRecords: [],
            chartParams: undefined,
            chartOptions: undefined,
            copied: false,
            hasPct: true,
            dimensions: {},
            systemColors: [
                '#006699',
                '#F59B00',
                '#08916D',
                '#529EE0',
                '#52B7D8',
                '#D9A6C2',
                '#FFB03B',
                '#54A77B',
                '#E16032',
                '#4FD2D2',
                '#F0E442',
                '#E287B2'
            ]
        }
    },

    computed: {
        ...mapState('global', ['shareableStores']),

        crudConfig() {
            let model = this.config.type + 'Dash'
            return this.$_.cloneDeep(this.$models[model])
        },

        visMenuItems() {
            let options = [{
                name: 'Edit Visualization',
                callback: v => {
                    this.$emit('edit', v)
                }
            }, {
                name: 'Delete Visualization',
                divider: true,
                callback: v => {
                    this.$emit('delete', v)
                }
            }]
            let goalOptions = [{
                name: this.config.goal ? 'Edit goal' : 'Set goal',
                callback: () => {
                    this.$emit('goal', this.config)
                }
            }]

            if (this.config.goal) {
                goalOptions.push({
                    name: 'Delete Goal',
                    callback: () => {
                        this.$emit('goal', this.config, true)
                    }
                })
            }

            goalOptions[goalOptions.length - 1].divider = true

            let otherOptions = [{
                name: 'Change chart type',
                callback: this.beginChangeChartType
            }, {
                name: this.config.publicLink ? 'Edit public image' : 'Set public image',
                callback: () => {
                    let className = '.' + this.classPrefix + this.config.id
                    let rect = document.querySelector('.widget' + className + '.dash').getBoundingClientRect()
                    this.$emit('publicImage', this.config, rect)
                }
            }]

            if (this.show === 'chart') {
                let showPct = false
                let showValue = false

                switch (this.showLabels) {
                    case 'both':
                        showValue = true
                        showPct = true
                        break
                    case 'count':
                        showValue = true
                        break
                    case true:
                        if (this.config.value === 'percentage') {
                            showPct = true
                        } else {
                            showValue = true
                        }
                        break
                    case 'pct':
                        showPct = true
                }

                otherOptions.push({
                    name: (showValue ? 'Hide' : 'Display') + ' Count on Chart',
                    callback: () => {this.toggleLabel('count')}
                })

                if (this.hasPct) {
                    otherOptions.push({
                        name: (showPct ? 'Hide' : 'Display') + ' Percentage on Chart',
                        callback: () => {this.toggleLabel('pct')}
                    })
                }

                otherOptions.push({
                    name: 'Print',
                    callback: this.handlePrint
                })
            } else if (this.show === 'table') {
                otherOptions.push({
                    name: 'Export CSV',
                    callback: () => {
                        this.$refs.table.exportCSV()
                    }
                })
            }

            return options.concat(goalOptions, otherOptions)
        },

        chartTypes() {
            let options = [{
                id: 'bar',
                name: 'Bar',
                description: '<b>Bar charts</b> are used to show how individual parts make up the whole',
                icon: 'fas fa-bars'
            }, {
                id: 'column',
                name: 'Column',
                description: '<b>Column charts</b> are used to compare values, show data distribution, and analyze trends',
                icon: 'fas fa-chart-bar'
            }, {
                id: 'numeric',
                name: 'Numeric',
                description: '<b>Numeric charts</b> are used to compare values by showing the high and low values in a set',
                icon: 'fas fa-hashtag'
            }, {
                id: 'trendline',
                name: 'Line',
                description: '<b>Line charts</b> are used to compare values, show data distribution, and understand relationships',
                icon: 'fas fa-chart-line'
            }, {
                id: 'table',
                name: 'Table',
                description: '<b>Tables</b> are used when individual, precise values are needed to compare',
                icon: 'fas fa-table'
            }]

            options = options.filter(x => x.id !== this.config.visualization)

            if (this.config.type === 'googlesheet') {
                options = options.filter(x => x.id !== 'numeric')
            }

            let addStacked = ['bar', 'column'].includes(this.config.visualization)
            let addBullet = !this.$_.isEmpty(this.config.goal) && this.config.type === 'assessment'

            if (addStacked || addBullet) {
                options[options.length - 1].divider = true
            }

            if (addStacked) {
                options.push({
                    id: 'stacked',
                    name: 'Stacked',
                    toggle: true,
                    property: 'stacked'
                })
            }

            if (addBullet) {
                options.push({
                    id: 'bulletGoal',
                    name: 'Bullet View',
                    toggle: true,
                    property: 'bulletGoal'
                })
            }

            return options
        },

        show() {
            if (this.noData) return false
            if (!this.ready) return 'loading'
            switch (this.config.visualization) {
                case 'numeric':
                case 'table':
                    return this.config.visualization
                default:
                    return this.chartOptions ? 'chart' : 'loading'
            }
        },

        showLabels() {
            return !this.thumbnail && this.config.showLabels
        },

        classPrefix() {
            return this.thumbnail ? 'w-thumb-' : 'w-'
        },

        publicLink() {
            let c = this.config
            if (c.publicLink) {
                return c.publicLink.hasOwnProperty('link') ? c.publicLink.link : c.publicLink
            }
        },

        scrollbarsEnabled() {
            if (this.publicImage) return false
            return this.thumbnail ? false : true
        }
    },

    watch: {
        config: {
            handler() {
                this.doVisualization()
                this.$nextTick(() => {
                    this.setDimensions()
                })
            }
        }
    },

    mounted() {
        this.doVisualization()
        this.setDimensions()
    },

    methods: {
        onIntersect(entries, observer, isIntersecting) {
            if (this.lazy && !this.lazyLoadReady && isIntersecting) {
                this.lazyLoadReady = true
                this.noData = false
                this.doVisualization()
            }
        },

        doVisualization() {
            // if this visualization has been marked as stale - refresh
            if (this.lazy) {
                if (this.$store.state.global.staleDashboardWidgets[this.config.id]) {
                    this.$store.commit('global/unflagStaleDashboardWidget', {id: this.config.id})
                    this.lazyLoaded = false
                } else if (this.lazyLoaded) { // if already loaded - skip reloading
                    return
                }
            }
            this.ready = false
            this.chartOptions = undefined
            let config = this.$_.cloneDeep(this.config)
            let params = {
                saved_search_id: config.saved_search_id,
                colors: config.colors || {}
            }

            if (config.scope) {
                params.scope = config.scope

                if (config.view_as_user_id) {
                    params.view_as_user_id = config.view_as_user_id
                }
            }

            switch (config.type) {
                case 'assessment':
                    delete params.colors
                    params.comparison = config.comparison
                    params.group_by = config.groupBy || 'district'
                    if (this.drilldown) {
                        switch (config.groupBy) {
                            case 'school':
                                params.school_id = config.school_id
                                break;
                            case 'school_group':
                                params.school_group_id = config.school_group_id
                                break;
                            case 'grade':
                                params.grade_id = config.grade_id
                                break;
                            case 'ethnicity':
                                params.ethnicity = config.ethnicity
                                break;
                            case 'gender':
                                params.gender = config.gender
                                break;
                        }
                        params.school_year_id = config.school_year_id
                        params.target_descriptor_id = config.target_descriptor_id
                        params.data_point_rank = config.data_point_rank
                        params.data_point_id = config.data_point_id
                    } else if (params.comparison === 'custom') {
                        let comparePoints = config.comparePoints
                        let schoolYears = comparePoints.years.map(x => x.id)
                        schoolYears.unshift(this.dashboard.school_year_id)
                        params.school_year_id = schoolYears.join(',')
                        params.data_point_rank = comparePoints.windows.map(x => x.id).join(',')
                    } else {
                        params.school_year_id = this.dashboard.school_year_id
                    }
                    break;
                case 'attendance':
                    if (this.drilldown) {
                        params.rate = config.rate
                    }
                    params.group_by = config.chart_types
                    params.school_year_id = this.dashboard.school_year_id
                    params.report_as = config.report_as || 'rate'
                    params.kpi_config_id = config.id
                    params.value = config.value
                    if (params.report_as === 'targets') params.target_type_id = config.target_type_id || 2
                    this.hasPct = params.report_as === 'targets'
                    break;
                case 'demographic':
                    params.demographic = config.demographic
                    params.group_by = config.group_by
                    params.value = config.value || 'count'
                    params.school_year_id = this.getSchoolYears(this.dashboard.school_year_name).map(x => x.id).join(',')
                    break;
                case 'incidentType':
                case 'incidentResponse':
                    if (this.drilldown) {
                        switch (config.group_by) {
                            case 'incident_motivation':
                                config.param_keys.push('incident_motivation_id')
                                break;
                        }
                    }
                    params.vis_type = config.type
                    params.group_by = config.chart_types
                    params.school_year_id = this.getSchoolYears(this.dashboard.school_year_name).map(x => x.id).join(',')
                    params.value = config.report_as
                    params.details = config.hasOwnProperty('details') ? config.details : 0
                    this.hasPct = false
                    break;
                case 'tag':
                    if (this.drilldown) {
                        params.tag_id = config.tag_id
                    }
                    params.school_year_id = this.getSchoolYears(this.dashboard.school_year_name).map(x => x.id).join(',')
                    this.hasPct = false
                    break;
                case 'googlesheet':
                    this.hasPct = false
                    var gs = true
                    if (this.lazy && !this.lazyLoadReady) {
                        this.setNoData("Scroll into view to load...")
                        break
                    }
                    this.$fetchGoogleSheet(config).then(cfg => {
                        if (!config.visualization) config.visualization = 'bar'
                        if (Object.keys(cfg.sheetValues).length) {
                            this.chartData = this.$buildChartFromSheet(cfg, config.flipGrouping)
                            this.chartParams = params
                            this.doChart()
                        } else {
                            this.setNoData()
                        }
                        if (this.lazy) this.lazyLoaded = true
                    }).catch(err => {
                        this.setNoData()
                        console.error('err', err)
                    })
                    break;
            }

            if (this.drilldown) {
                Object.keys(params).forEach(x => {
                    if (config.hasOwnProperty(x)) {
                        params[x] = config[x]
                    }
                })
                if (config.param_keys?.length) {
                    config.param_keys.forEach(x => {
                        params[x] = config[x]
                    })
                    params.param_keys = config.param_keys.join(',')
                }
                params.group_by = config.group_by
                params.drilldown = 1
            }

            if (!gs) {
                if (this.lazy && !this.lazyLoadReady) {
                    this.setNoData("Scroll into view to load...")
                    return
                }
                this.$refs.crud.read(params)
                    .then(res => {
                        let data = res.data.data
                        if (this.$_.isEmpty(data?.categories)) {
                            this.setNoData()
                        } else {
                            this.setNoData(false)
                            this.chartData = data
                            this.chartParams = params
                            this.doChart()
                        }
                        if (this.lazy) this.lazyLoaded = true
                    })
                    .catch(() => {
                        this.setNoData()
                    })
            }
        },

        doChart() {
            let data = this.chartData
            let params = this.chartParams
            let colors = params.colors

            let systemColors = this.systemColors
            let nextColor = 0

            let type = this.config.visualization
            switch (type) {
                case 'numeric':
                    if (!data.numeric.length) {
                        this.setNoData()
                    } else {
                        let dashYear = this.dashboard.school_year_name
                        nextColor = data.series.length - 1

                        // Combine and add values for demographic options with aliases
                        if (this.config.type === 'demographic') {
                            let numData = data.numeric.reduce((obj, item) => {
                                if (item.alias) {
                                    item.count = parseFloat(item.count)
                                    let find = obj.find(i => i.alias === item.alias)
                                    let _d = {
                                        ...item,
                                        name: item.alias
                                    }
                                    find ? (find.count += item.count) : obj.push(_d);
                                    return obj;
                                } else {
                                    obj.push(item);
                                    return obj;
                                }
                                }, [])
                            data.numeric = numData
                        }

                        if (!this.thumbnail) {
                            this.numericRecords = data.numeric.map(item => {
                                if (colors && colors[dashYear]) {
                                    item.color = colors[dashYear]
                                } else if (!['attendance', 'assessment'].includes(this.config.type)) {
                                    item.color = systemColors[nextColor]
                                }

                                if (this.config.type === 'demographic' && this.config.value === 'percentage') item.count = parseFloat(item.count).toFixed(2) + '%'

                                item.clickHandler = () => {
                                    if (this.allowDrilldown && item.count) {
                                        let pointParams = Object.assign(params, item.params)
                                        this.$emit('drilldown', this.config, pointParams)
                                    }
                                }

                                return item
                            });
                        } else {
                            let item = data.numeric[0]
                            if (colors && colors[dashYear]) {
                                item.color = colors[dashYear]
                            } else if (!['attendance', 'assessment'].includes(this.config.type)) {
                                item.color = systemColors[nextColor]
                            }

                            item.clickHandler = () => {
                            }
                            this.numericRecords = [item]
                        }
                    }
                    break;
                case 'table':
                    data.series = data.series.map(item => {
                        if (item.color && colors && colors[item.name]) {
                            item.color = colors[item.name]
                        }

                        return item
                    })
                    break;
                default:
                    let chartOptions = this.setChartOptions()

                    let allCategories
                    if (!this.drilldown && this.config.type !== 'googlesheet') {
                        allCategories = data.numeric.map(nData => {return {'name': nData.name, 'incident_behavior_type_id': nData.params.incident_behavior_type_id}})
                        this.nameSort(allCategories, 'name')
                    }

                    let seriesArray = data.series.map(item => {
                        if (colors && colors[item.name]) {
                            item.color = colors[item.name]
                        } else if (!item.color) {
                            item.color = systemColors[nextColor]
                            nextColor++
                            if (nextColor >= systemColors.length) {
                                nextColor = 0
                            }
                        }

                        // get and include incident category name for data sorting
                        if (params.group_by === 'incident_behavior_type') {
                            for (let id in item.data) {
                                let category = data.numeric.filter(n => n.params.incident_behavior_type_id == item.data[id].incident_behavior_type_id)
                                item.data[id].category = category[0].name
                            }

                            if (!this.drilldown) {
                                item.data.categories = allCategories.map(cat => cat.name)

                                // add dummy data when a series doesn't come back with the same number of data points as its counterpart(s)
                                if (item.data.length != allCategories.length) {
                                    const currentSeriesIds = item.data.map(dataElement => dataElement.incident_behavior_type_id)
                                    const allIds = allCategories.map(cat => cat.incident_behavior_type_id)
                                    const missingIds = allIds.filter(x => !currentSeriesIds.includes(parseInt(x)))
                                    if (missingIds.length) {
                                        missingIds.forEach(missingId => {
                                            item.data.push({
                                                "school_year_id": item.data[0].school_year_id,
                                                "details": "0",
                                                "param_keys": [
                                                    "incident_behavior_type_id"
                                                ],
                                                "incident_behavior_type_id": parseInt(missingId),
                                                "pct": 0.00,
                                                "count": 0,
                                                "y": 0,
                                                "category": allCategories.find(cat => cat.incident_behavior_type_id === parseInt(missingId)).name
                                            })
                                        })
                                    }
                                }
                                item.data = this.nameSort(item.data, 'category')
                            }
                        }


                        if (this.allowDrilldown && this.config.type !== 'googlesheet') {
                            item.events = {
                                click: (clickEvent) => {
                                    let pointOptions = clickEvent.point.options
                                    let pointParams = Object.assign(params, pointOptions)
                                    let config = this.$_.cloneDeep(this.config)
                                    this.$emit('drilldown', config, pointParams)
                                }
                            }
                        }

                        return item
                    })

                    let sortCategories = data.categories
                    if (params.group_by === 'incident_behavior_type') sortCategories = this.nameSort(data.categories)

                    chartOptions.xAxis.categories = sortCategories
                    chartOptions = this.setGoal(chartOptions, seriesArray)

                    let hasDecimals = false
                    if (this.config.yAxisMin) {
                        chartOptions.yAxis.min = parseFloat(this.config.yAxisMin)

                        if (this.config.yAxisMin.toString().indexOf('.') > -1) hasDecimals = true
                    } else {
                        // deleting yAxis.min is causing the yAxis min to show negative values
                        if(!this.scrollbarsEnabled) delete chartOptions.yAxis.min
                        else chartOptions.yAxis.min = 0
                    }

                    if (this.config.yAxisMax) {
                        chartOptions.yAxis.max = parseFloat(this.config.yAxisMax)
                        if (this.config.yAxisMax.toString().indexOf('.') > -1) hasDecimals = true
                    } else if (this.config.value === 'percentage') {
                        // if user wants to see Percent of Students
                        // make sure we plot percentages rather than counts
                        chartOptions.yAxis.max = 100
                        let series = chartOptions.series
                        series.forEach(s => {
                            s.data = s.data.map(x => {
                                x.count = x.y
                                x.y = x.pct
                                return x
                            })
                        })
                    } else if (this.scrollbarsEnabled) {
                        let max = 0
                        data.series.forEach(dataSet => {
                            let yValues = dataSet.data.map(data => data.y)
                            let maxY = Math.max(...yValues)
                            max = maxY > max ? maxY : max
                        })
                        chartOptions.yAxis.max = max
                    } else {
                        delete chartOptions.yAxis.max
                    }

                    if (hasDecimals) {
                        chartOptions.yAxis.tickInterval = .1
                        chartOptions.yAxis.labels = {
                            format: '{value: .1f}'
                        }
                    }

                    this.chartOptions = chartOptions
                    this.adjustXMax()
            }

            this.$nextTick(() => {
                this.ready = true
            })
        },

        nameSort(items, sortBy=null) {
            if (items.length <= 1) return items
            return items.sort((a, b) => {
                let aVal = !sortBy ? a : a[sortBy]
                let bVal = !sortBy ? b : b[sortBy]

                if (typeof (aVal) === 'string' && typeof (bVal) === 'string') {
                    if (aVal.toLowerCase() < bVal.toLowerCase()) {
                        return -1
                    } else if (aVal.toLowerCase() > bVal.toLowerCase()) {
                        return 1
                    } else {
                        return 0
                    }
                }
                return 0
            })
        },

        setChartOptions() {
            let chartOptions = this.defaultChartOptions()

            switch (this.config.visualization) {
                case 'bar':
                    chartOptions.chart.type = 'bar'
                    if (this.config.stacked) {
                        chartOptions.plotOptions.series.stacking = this.value === 'percentage' ? 'percent' : 'normal'
                    } else {
                        chartOptions.plotOptions.bar = {
                            pointPadding: 0.2,
                            borderWidth: 0
                        }

                        chartOptions.plotOptions.series.stacking = undefined
                    }
                    break;
                case 'column':
                    chartOptions.chart.type = 'column'
                    if (this.config.stacked) {
                        chartOptions.plotOptions.series.stacking = this.value === 'percentage' ? 'percent' : 'normal'
                    } else {
                        chartOptions.plotOptions.column = {
                            pointPadding: 0.2,
                            borderWidth: 0
                        }

                        chartOptions.plotOptions.series.stacking = undefined
                    }
                    break;
                case 'trendline':
                    chartOptions.chart.type = 'line'
                    chartOptions.plotOptions.series.stacking = undefined
                    chartOptions.plotOptions.series.label = {connectorAllowed: false}
                    break;
            }

            if (this.thumbnail) {
                chartOptions.legend.enabled = false
            }

            return chartOptions
        },

        drawBullet(c) {
            if (!this.config.bulletGoal) return

            let chart = c.target
            let xAxis = chart.xAxis[0]

            this.bullets.forEach(b => {
                b.destroy()
            })

            this.bullets = []

            xAxis.categories.forEach((x, i) => {
                let width = 0
                let height = 0
                xAxis.series.forEach(s => {
                    if (this.goalDescriptors.includes(s.name)) {

                        let point = s.data[i]
                        // Depending on the horiz value of xAxis height and width can be flipped.  This was developed in
                        // bar chart mode first :/
                        if (point) {
                            width += point.shapeArgs.height
                            height = point.shapeArgs.width * .3
                        }

                    }
                })
                let offset = height / 2

                /**
                 Highcharts uses the xAxis definition in both bar/column chart types.  This means we have to account for different
                 start/end width/height coordinates if the chart xAxis is set to horizontal.
                 */
                let barX = xAxis.horiz ? xAxis.toPixels(i) - offset : xAxis.left
                let barY = xAxis.horiz ? (chart.chartHeight - xAxis.bottom) - width : xAxis.toPixels(i) - offset
                let barWidth = xAxis.horiz ? height : width
                let barHeight = xAxis.horiz ? width : height

                this.bullets.push(chart.renderer.rect(barX, barY, barWidth, barHeight).attr({
                    'stroke-width': 0,
                    stroke: 'black',
                    fill: 'black',
                    opacity: .6,
                    zIndex: 3
                }).addClass('rect').add())
            })
        },

        defaultChartOptions() {
            let me = this
            return {
                chart: {
                    type: 'column',
                    zoomType: 'xy',
                    alignTicks: false,
                    events: {
                        afterPrint: () => {
                            this.$emit('refresh')
                        },
                        redraw: c => {
                            this.drawBullet(c)
                        }
                    }
                },
                legend: {
                    align: 'center'
                },
                tooltip: {
                    useHTML: true,
                    formatter: function () {
                        let series = this.series
                        let point = this.point

                        let value = +point.y.toFixed(2)
                        let pct = ''

                        if (me.config.value === 'percentage') {
                            value += '%'
                            pct = ' (' + +point.count.toFixed(2) + ')<br>'
                        } else if (point.pct) {
                            pct = ' (' + +point.pct.toFixed(2) + '%)<br>'
                        } else if (this.percentage) {
                            pct = ' (' + +this.percentage.toFixed(2) + '%)<br>'
                        }

                        return series.name + ': <b>' + value + '</b>' + pct
                    }
                },
                plotOptions: {
                    series: {
                        turboThreshold: 0,
                        dataLabels: {
                            enabled: this.showLabels,
                            formatter: function () {
                                let point = this.point
                                let count = point.hasOwnProperty('count') ? +point.count.toFixed(2) : +point.y.toFixed(2)
                                let pct = +point.pct?.toFixed(2) + '%'
                                let display = ''
                                let percentPrimary = me.config.value === 'percentage'

                                switch (me.showLabels) {
                                    case 'count':
                                        display += count
                                        break
                                    case 'pct':
                                        if (me.hasPct) display += pct
                                        break
                                    case true:
                                        if (me.hasPct) {
                                            display += percentPrimary ? pct : count
                                        } else {
                                            display += count
                                        }
                                        break
                                    default:
                                        if (percentPrimary) {
                                            display += pct + ' (' + count + ')'
                                        } else if (me.hasPct) {
                                            display += count + ' (' + pct + ')'
                                        } else {
                                            display += count
                                        }
                                }

                                return display
                            }
                        }
                    },
                    column: {
                        stacking: this.scrollbarsEnabled ? 'normal' : '',
                        dataLabels: {
                            enabled: this.scrollbarsEnabled ? true : false,
                        },
                    },
                },
                title: {
                    text: ''
                },
                xAxis: {
                    min: 0,
                    max: this.scrollbarsEnabled ? this.chartData?.categories?.length > 5 ? 5 : null : null,
                    categories: [],
                    scrollbar: {
                        enabled: this.scrollbarsEnabled ? this.chartData?.categories?.length < 6 ? false : true : false,
                        showFull: false
                    },
                },
                yAxis: {
                    reversedStacks: false,
                    startOnTick: false,
                    endOnTick: false,
                    min: 0,
                    title: {
                        text: ''
                    },
                },
                series: []
            }
        },

        setGoal(chartOptions, seriesArray) {
            if (!this.thumbnail && this.config.goal) {
                let goal = this.config.goal
                let type = this.config.visualization
                let goalValue = parseFloat(goal.value)
                let percentage = goal.type === 'pct'
                if (percentage) {
                    chartOptions.yAxis.max = 100
                }

                let description = ''

                let stackable = ['bar', 'column'].includes(type)

                if (this.config.type === 'assessment') {
                    /** Fetch Bands **/
                    this.getSSValues(goal.saved_search_id)
                        .then(res => {
                            let ssValues = res.savedSearch
                            if (ssValues?.target_descriptor_id.length) {
                                let descriptors = ssValues.target_descriptor_id.map(x => x.name)

                                this.goalDescriptors = descriptors

                                seriesArray = seriesArray.map(series => {
                                    if (stackable) {
                                        series.type = 'bullet'
                                        series.targetOptions = {
                                            width: '120%',
                                            height: 2,
                                            borderWidth: 0,
                                            borderColor: 'black',
                                            color: 'black'
                                        }
                                    }

                                    let target = 0
                                    if (descriptors.includes(series.name) || this.config.bulletGoal) {
                                        target = goalValue
                                    } else {
                                        series.visible = false
                                    }

                                    series.data = series.data.map(x => {
                                        if (percentage) {
                                            x.count = x.y
                                            x.y = x.pct
                                        }
                                        x.target = target
                                        return x
                                    })

                                    return series
                                })

                                description += ' Goal: '
                                switch (goal.operator) {
                                    case 'greater':
                                        description += 'Greater than '
                                        break;
                                    case 'less':
                                        description += 'Less than '
                                        break;
                                    default:
                                        description += 'Equal to '
                                }
                                description += goalValue + (percentage ? '%' : '')

                                if (descriptors.length > 1) {
                                    description += ' in:'
                                    descriptors.forEach(x => {
                                        description += ' <br> &Tab; &bullet; ' + x
                                    })
                                }

                                if (goal.description) {
                                    description += '<br>' + goal.description
                                }

                                if (!stackable) {
                                    this.chartOptions.yAxis.plotLines = [{
                                        color: '#7ee26f',
                                        dashStyle: 'shortdash',
                                        value: goalValue,
                                        width: 2,
                                    }]
                                }
                            }
                        })
                } else {
                    /** Set stacking type based on chart and value type **/
                    if (this.config.stacked && stackable) {
                        chartOptions.plotOptions.series.stacking = percentage ? 'percent' : 'normal'
                    }

                    /** Add plotline to chart for goal level **/
                    chartOptions.yAxis.plotLines = [{
                        color: '#7ee26f',
                        dashStyle: 'shortdash',
                        value: goalValue,
                        width: 2,
                    }]

                    /** Define basic goal information for tooltip **/
                    description += ' Goal: '

                    switch (goal.operator) {
                        case 'greater':
                            description += 'Greater Than '
                            break;
                        case 'less':
                            description += 'Less Than '
                            break;
                        case 'equal':
                            description += 'Exactly '
                            break;
                    }

                    description += goalValue

                    if (percentage) description += '&percnt;'

                    if (goal.description) description += '<br>' + goal.description
                }

                /** Update tooltip with basic goal information **/
                chartOptions.tooltip.formatter = function () {
                    let series = this.series
                    let point = this.point

                    let value = +point.y.toFixed(2)
                    let pct = ''

                    if (value === point.pct) {
                        value += '%'
                        pct = ' (' + +point.count.toFixed(2) + ')<br>'
                    } else if (point.pct) {
                        pct = ' (' + +point.pct.toFixed(2) + '%)<br>'
                    } else if (this.percentage) {
                        pct = ' (' + +this.percentage.toFixed(2) + '%)<br>'
                    }

                    let base = series.name + ': <b>' + value + '</b>' + pct
                    return base + description
                }
            }

            chartOptions.series = seriesArray

            return chartOptions
        },

        getSchoolYears(startingYear, numPrevious = 1) {
            let schoolYears = this.shareableStores.school_years
            let collection = [startingYear]

            for (let i = 0; i < numPrevious; i++) {
                let inc = i + 1
                collection.push(startingYear.match(/([0-9]+)-([0-9]+)/).slice(1, 3).map(x => x - inc).join('-'))
            }

            return schoolYears.filter(x => collection.includes(x.name))
        },

        setNoData(message = null) {
            if (false === message) {
                /** Reset No Data to false **/
                this.noData = false
                this.$emit('empty', false)
            } else if (null === message && this.thumbnail) {
                this.$emit('empty', true)
            } else if (null === message) {
                /** Default No Data Message **/
                this.noData = ["Oops! There is no data to display.", "Edit your visualization using different parameters."]
            } else {
                /** Custom No Data Message (must be array) **/
                this.noData = Array.isArray(message) ? message : [message]
            }
        },

        beginChangeChartType(name, e) {
            e.preventDefault()
            let x = e.target.parentElement.parentElement.getBoundingClientRect()
            this.editType.show = false
            this.editType.x = x.right - 163
            this.editType.y = x.y
            this.$nextTick(() => {
                this.editType.show = true
            })
        },

        changeChartType(type) {
            let change = {}
            switch (type) {
                case 'stacked':
                case 'bulletGoal':
                    change[type] = !this.config[type]
                    break;
                default:
                    change.visualization = type
            }
            this.editType.show = false
            let newConfig = Object.assign({}, this.config, change)
            if (!newConfig.config.hasOwnProperty(type)) {
                newConfig.config[type] = change[type]
            }
            this.$emit('save', newConfig)
        },

        toggleLabel(type) {
            let newShowLabels = false
            switch (this.showLabels) {
                case 'both':
                    newShowLabels = type === 'count' ? 'pct' : 'count'
                    break
                case false:
                case undefined:
                    newShowLabels = type
                    break
                /** This case is to account for switching this value from a boolean to a string **/
                case true:
                    let baseValue = this.config.value === 'percentage' ? 'pct' : 'count'
                    if (type !== baseValue) {
                        newShowLabels = this.hasPct ? 'both' : 'count'
                    }
                    break
                default:
                    if (this.showLabels !== type) {
                        newShowLabels = 'both'
                    }
            }

            //** To account for attendance charts that had percent showing **//
            if (newShowLabels === 'pct' && !this.hasPct) newShowLabels = false

            let newConfig = this.$_.cloneDeep(this.config)

            newConfig.showLabels = newShowLabels
            newConfig.config.showLabels = newShowLabels

            this.$emit('save', newConfig)
        },

        setDimensions() {
            let className = '.' + this.classPrefix + this.config.id
            let height = document.querySelector('.widget' + className).getBoundingClientRect().height
            if (!this.thumbnail && !this.drilldown) {
                let header = document.querySelector('.widget-header' + className).getBoundingClientRect().height

                height -= header

                if (this.config.goal) {
                    let footer = document.querySelector('.widget-footer' + className).getBoundingClientRect().height
                    height -= footer
                }
            }

            this.dimensions = {
                height: height.toString() + 'px'
            }
        },

        adjustXMax() {
            if (this.scrollbarsEnabled && this.chartOptions) {
                let categoryLength = this.chartData.categories.length
                switch (this.config.w) {
                    case 1:
                        this.chartOptions.xAxis.max = categoryLength > 2 ? 2 : null
                        this.chartOptions.xAxis.scrollbar.enabled = true
                        break;
                    case 2:
                        this.chartOptions.xAxis.max = categoryLength > 5 ? 5 : null
                        this.chartOptions.xAxis.scrollbar.enabled = categoryLength < 6 ? false : true
                        break;
                    case 3:
                        this.chartOptions.xAxis.max = categoryLength > 12 ? 11 : null
                        this.chartOptions.xAxis.scrollbar.enabled = categoryLength < 12 ? false : true
                        break;
                    default:
                        this.chartOptions.xAxis.max = null
                        this.chartOptions.xAxis.scrollbar.enabled = categoryLength < 12 ? false : true
                        break;
                }
                if (this.$refs?.highchart?.chart) {
                    this.$refs.highchart.chart.xAxis[0].setExtremes(this.chartOptions.xAxis.min, this.chartOptions.xAxis.max)
                }
            }
        },

        copyLink() {
            let $el = this.$refs.copyLink.$refs.input
            navigator.clipboard.writeText($el.value)
                .then(() => {
                    $el.blur()
                    window.getSelection().removeAllRanges()
                    this.copied = true
                    setTimeout(() => {
                        this.copied = false
                    }, 2000)
                })
                .catch(err => {
                    console.error(err)
                    $el.blur()
                    window.getSelection().removeAllRanges()
                })
        },

        handlePrint() {
            let toPrint = this.$refs.highchart.chart.container
            let newTab = window.open();
            newTab.document.write(toPrint.innerHTML);
            newTab.document.close();
            newTab.focus();
            newTab.print();
            newTab.close();
        }
    }
}
</script>

<style lang="scss" scoped>
.widget {
    &.dash {
        background: #FFFFFF;
        border: 1px solid #E0E1E6;
        border-radius: 5px;
        width: calc(100% - 14px);

        &.drilldown {
            width: 100%;
        }

        .widget-body {
            padding: 12px;
        }
    }

    .widget-header {
        height: 46px;
        border-bottom: 1px solid #E0E1E6;

        .hidden-link {
            height: 0;
            width: 0;
            border: none;
            margin: 0;
            visibility: hidden;
        }
    }

    .widget-body {
        .no-data {
            margin: 0;
            position: absolute;
            top: 50%;
            left: 50%;
            text-align: center;
            transform: translate(-50%, -50%);

            ul {
                list-style-type: none;
            }
        }

        .drillable {
            cursor: pointer;
        }
    }

    .widget-footer {
        padding: 12px;
        border-top: 1px solid #E0E1E6;

        .goal-flag {
            margin-top: 5px;
            margin-right: 8px;
            font-size: 12px;
        }
    }
}

.chart-type-menu {
    width: 163px;

    ::v-deep .theme--light.v-list-item {
        color: #050F2D !important;
    }

    .menu-icon {
        margin-right: 6px !important;

        ::v-deep .v-icon {
            color: #050F2D;
        }
    }

    .toggle-icon {
        color: #e0e1e6;
        font-size: 40px !important;

        &.on {
            color: var(--primary-color) !important;
        }
    }

    .divider {
        padding-bottom: 4px;
        margin-bottom: 4px;
        border-bottom: 1px solid #E0E1E6;
    }
}
</style>

<template>
    <div>
        <v-form v-if="ready" ref="form" @submit.prevent v-model="isValid">
            <fe-label class="mt-0">Visualization Name</fe-label>
            <v-text-field
                v-model="config.name"
                counter=50
                :rules="this.$fieldValidators('text', null, {required: true, limit: 50})"
                flat solo dense clearable autofocus
            />

            <fe-remote-combo
                label="Assessment Type"
                :url="assessmentTypeUrl"
                v-model="savedSearch.data_point_type_id"
                :rules="requiredRule"
                byId
            />

            <fe-remote-combo
                label='Focus Assessment'
                :url="assessmentUrl"
                rootProperty="subcategories"
                v-model="savedSearch.sub_category_id"
                :disabled="!savedSearch.data_point_type_id"
                :rules="requiredRule"
                byId
            />

            <fe-remote-combo
                label='Select the Target Set / Performance Band'
                :url="targetSetUrl"
                v-model="savedSearch.target_set_id"
                :disabled="!savedSearch.sub_category_id"
                :rules="requiredRule"
                byId
            />

            <fe-remote-combo
                label='Select the Target Set / Performance Band Type'
                :url="targetSetTypeUrl"
                v-model="config.targetSetType"
                :disabled="!savedSearch.target_set_id"
                :rules="requiredRule"
                byId
            />

            <fe-remote-combo
                label='Select the Specific Target Range / Color'
                :url="targetDescriptorUrl"
                rootProperty="descriptors"
                v-model="savedSearch.target_descriptor_id"
                :disabled="!config.targetSetType"
                :rules="requiredRule"
                byId
                multiple
            >
                <template slot="selection" slot-scope="data">
                    <fe-chip
                        :input-value="data.selected"
                        @click:close="remove(data.item, 'target_descriptor_id')"
                        close
                    >
                        {{ data.item.name }}
                    </fe-chip>
                </template>
                <template slot="item" slot-scope="data">
                    <v-list-item-avatar>
                        <v-icon
                            class="fas fa-square-full color-swatch"
                            :color="data.item.color"
                            small
                        />
                    </v-list-item-avatar>
                    <v-list-item-content>
                        <v-list-item-title v-html="data.item.name"/>
                    </v-list-item-content>
                </template>
            </fe-remote-combo>

            <fe-remote-combo
                label='Select a Comparison Point'
                :items="compareOptions"
                v-model="config.comparison"
                :rules="requiredRule"
                byId
            />

            <div v-if="config.comparison === 'custom'">
                <fe-remote-combo
                    ref="custom_years"
                    label="Select Years for Comparison with Current Year"
                    v-model="config.comparePoints.years"
                    :items="schoolYears"
                    :disabled="customComparatorsDisabled"
                    multiple
                >
                    <template slot="selection" slot-scope="data">
                        <fe-chip
                            :input-value="data.selected"
                            @click:close="remove(data.item, 'years')"
                            close
                        >
                            {{ data.item.name }}
                        </fe-chip>
                    </template>
                </fe-remote-combo>

                <fe-remote-combo
                    ref="custom_windows"
                    label="Select Windows for Comparison"
                    v-model="config.comparePoints.windows"
                    :items="windows"
                    :disabled="customComparatorsDisabled"
                    :rules="requiredRule"
                    multiple
                >
                    <template slot="selection" slot-scope="data">
                        <fe-chip
                            :input-value="data.selected"
                            @click:close="remove(data.item, 'windows')"
                            close
                        >
                            {{ data.item.name }}
                        </fe-chip>
                    </template>
                </fe-remote-combo>
            </div>

            <fe-remote-combo
                label="Report As"
                :items="reportAsOptions"
                v-model="config.value"
                :rules="requiredRule"
                byId
            />

            <group-by v-model="config.groupBy" :visType="config.config.type"/>

            <vis-type v-model="config.visualization"/>

            <student-filters
                v-if="!globalFilters"
                :filters="studentFilters"
                :dashboard="dashboard"
                @change="studentFilters = $event"
            />

            <fe-label>Enter a Short Description</fe-label>
            <v-textarea
                v-model="config.description"
                counter=255
                rows=4
                :rules="this.$fieldValidators('text', null, {required: false, limit: 255})"
                flat solo dense no-resize
            />

            <display-settings :value="displaySettings" @change="displaySettings = $event"/>
        </v-form>
    </div>
</template>

<script>
import {mapState} from "vuex"
import VisType from "./fields/VisType"
import GroupBy from "./fields/GroupBy"
import StudentFilters from "./fields/StudentFilters"
import DisplaySettings from "./fields/DisplaySettings"

export default {
    name: "Assessment",

    components: {
        VisType,
        GroupBy,
        StudentFilters,
        DisplaySettings
    },

    props: {
        dashboard: {
            type: Object,
            required: true
        },
        edit: {
            type: Object,
            required: false
        },
        getSSValues: {
            type: Function,
            required: true
        },
        getSchoolYears: {
            type: Function,
            required: true
        },
        getObjId: {
            type: Function,
            required: true
        },
        setConfig: {
            type: Function,
            required: true
        },
        globalFilters: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            ready: false,
            validationReady: false,
            isValid: false,
            config: {
                name: '',
                description: '',
                targetSetType: undefined,
                comparison: undefined,
                groupBy: undefined,
                value: undefined,
                visualization: undefined,
                yAxisMin: undefined,
                yAxisMax: undefined,
                comparePoints: {
                    years: [],
                    windows: []
                },
                config: {
                    type: 'assessment',
                    description: '',
                    targetSetType: undefined,
                    comparison: undefined,
                    groupBy: undefined,
                    value: undefined,
                    visualization: undefined,
                    yAxisMin: undefined,
                    yAxisMax: undefined,
                    comparePoints: {
                        years: [],
                        windows: []
                    }
                }
            },
            savedSearch: {
                data_point_type_id: undefined,
                sub_category_id: undefined,
                target_set_id: undefined,
                target_descriptor_id: undefined,
                school_id: undefined,
                grade_id: undefined,
                ethnicity: undefined,
                disability_type_id: undefined,
                gender: undefined,
                tag_id: undefined,
                meal_status_id: undefined,
                ell_flag: undefined,
                ell_level_id: undefined,
                student_active_flag: undefined,
                student_active_date: undefined,
                demographic: undefined
            },
            schoolYears: [],
            windows: [],
            compareOptions: [{
                name: 'Current year, previous window',
                id: 'currentYear'
            }, {
                name: 'Last year, same window',
                id: 'previousYear'
            }, {
                name: 'Custom',
                id: 'custom'
            }],
            stackedOptions: [{
                name: 'Yes',
                id: 1
            }, {
                name: 'No',
                id: 0
            }]
        }
    },

    computed: {
        ...mapState('global', ['shareableStores']),

        displaySettings: {
            get() {
                return {
                    yAxisMin: this.config.yAxisMin,
                    yAxisMax: this.config.yAxisMax
                }
            },

            set(v) {
                Object.keys(v).forEach(x => {
                    if (this.config.hasOwnProperty(x)) {
                        this.config[x] = v[x]
                    }
                })
            }
        },

        studentFilters: {
            get() {
                return {
                    school_id: this.savedSearch.school_id,
                    grade_id: this.savedSearch.grade_id,
                    ethnicity: this.savedSearch.ethnicity,
                    disability_type_id: this.savedSearch.disability_type_id,
                    gender: this.savedSearch.gender,
                    tag_id: this.savedSearch.tag_id,
                    meal_status_id: this.savedSearch.meal_status_id,
                    ell_flag: this.savedSearch.ell_flag,
                    ell_level_id: this.savedSearch.ell_level_id,
                    student_active_flag: this.savedSearch.student_active_flag,
                    student_active_date: this.savedSearch.student_active_date,
                    demographic: this.savedSearch.demographic
                }
            },

            set(v) {
                Object.keys(v).forEach(x => {
                    if (this.savedSearch.hasOwnProperty(x)) {
                        this.savedSearch[x] = v[x]
                    }
                })
            }
        },

        assessmentTypeUrl() {
            let url = this.$models.getUrl('dataPointType', 'read') + '&active=1'
            let years = this.getSchoolYears(this.dashboard.school_year_name).map(x => x.id).join(',')
            url += '&school_year_id=' + years
            return url
        },

        assessmentUrl() {
            let dataPointTypeId = this.getObjId(this.savedSearch.data_point_type_id)
            if (dataPointTypeId) {
                return this.$models.getUrl('subCategory', 'read') + '&data_point_type_id=' + dataPointTypeId
            } else {
                return ''
            }
        },

        targetSetUrl() {
            let dataPointTypeId = this.getObjId(this.savedSearch.data_point_type_id)
            if (dataPointTypeId) {
                return this.$models.getUrl('targetSet', 'read') + '&active=1&data_point_type_id=' + dataPointTypeId
            } else {
                return ''
            }
        },

        targetSetTypeUrl() {
            return this.$models.getUrl('targetType', 'read') + '&id=1,3'
        },

        targetDescriptorUrl() {
            let type = this.config.targetSetType
            let set = this.savedSearch.target_set_id
            if (set && set.hasOwnProperty('id')) set = set.id
            if (set && type) {
                let url = this.$models.getUrl('targetSetDescriptor', 'read')
                url += '&types=' + type
                url += '&target_set_id=' + set
                return url
            } else {
                return ''
            }
        },

        customComparatorsDisabled() {
            return !this.savedSearch.data_point_type_id || !this.savedSearch.sub_category_id
        },

        requiredRule() {
            if (this.validationReady) {
                return this.$fieldValidators('select', null, {required: true})
            }
            return
        },

        reportAsOptions() {
            let opts = [{
                id: 'student_total',
                name: 'Total Students'
            }, {
                id: 'percentage',
                name: 'Percent of Students'
            }]

            return opts
        }
    },

    watch: {
        isValid(n, o) {
            if (n !== o) {
                this.$emit('valid', n)
            }
        },

        config: {
            deep: true,
            handler(v) {
                this.$emit('change', v, 'config')
            }
        },

        savedSearch: {
            deep: true,
            handler(v) {
                this.$emit('change', v, 'savedSearch')
            }
        },

        'savedSearch.data_point_type_id': {
            deep: true,
            handler(n, o) {
                if (o && n !== o) {
                    this.savedSearch.sub_category_id = undefined
                }
            }
        },

        'savedSearch.sub_category_id': {
            deep: true,
            handler(n, o) {
                if (o && n !== o) {
                    this.savedSearch.target_set_id = undefined
                }
                if (n) {
                    this.getComparators()
                }
            }
        },

        'savedSearch.target_set_id': {
            deep: true,
            handler(n, o) {
                if (o && n !== o) {
                    this.config.targetSetType = undefined
                }
            }
        },

        'config.targetSetType': {
            deep: true,
            handler(n, o) {
                if (o && n !== o) {
                    this.savedSearch.target_descriptor_id = undefined
                }
            }
        },

        'config.comparison': {
            deep: true,
            handler(n) {
                if (n !== 'custom') {
                    this.config.comparePoints = {
                        years: [],
                        windows: []
                    }
                }
            }
        },
    },

    mounted() {
        let awaiting = []

        if (this.edit) {
            this.config = this.setConfig(this.config, this.edit)
            awaiting.push(this.getSSValues(this.config.saved_search_id, this.savedSearch))
        }

        Promise.all(awaiting)
            .then(() => {
                if (this.globalFilters) {
                    this.getSSValues(this.dashboard.saved_search_id, this.savedSearch)
                        .then(() => {
                            this.ready = true
                            setTimeout(() => {
                                this.validationReady = true
                            }, 1000)
                        })
                } else {
                    this.ready = true
                    setTimeout(() => {
                        this.validationReady = true
                    }, 1000)
                }
            })
    },

    methods: {
        remove(v, key) {
            if (this.config.hasOwnProperty(key)) {
                this.config[key] = this.config[key].filter(x => (x.id !== v.id) && (x !== v.id))
            } else if (this.savedSearch.hasOwnProperty(key)) {
                this.savedSearch[key] = this.savedSearch[key].filter(x => (x.id !== v.id) && (x !== v.id))
            } else if (this.config.comparePoints.hasOwnProperty(key)) {
                this.config.comparePoints[key] = this.config.comparePoints[key].filter(x => (x.id !== v.id) && (x !== v.id))
            }
        },

        getComparators() {
            this.schoolYears = []
            this.windows = []
            let savedSearch = this.savedSearch
            let p = {
                data_point_type_id: this.getObjId(savedSearch.data_point_type_id),
                sub_category_id: this.getObjId(savedSearch.sub_category_id),
                property: 'compare_points'
            }
            let url = this.$models.getUrl('targetScores', 'read') + "&" + this.$objectToParams(p)

            this.$axios.get(url).then(response => {
                let comp = response.data.data
                this.windows = comp.windows || []
                this.schoolYears = comp.years.filter(x => x.id < this.dashboard.school_year_id) || []
            })
        },
    }
}
</script>

<style lang="scss" scoped>

</style>

<template>
    <fe-dialog
        :title="title"
        width="900"
        height="600"
        dismissable
        dismissButtonText="Cancel"
        :acceptButtonText="acceptButtonText"
        :acceptButtonDisabled="!valid"
        @accept="save"
        @dismiss="$emit('close')"
    >
        <p v-if="ready && !clone">
            Easily create a dashboard to visualize and share the insights about your data. These visualizations can
            include data from assessments, attendance, incidents, tags and demographic information that were pulled
            into the database as well as data from a Google Sheet such as survey results or financial data.
        </p>

        <v-form v-if="ready" ref="form" @submit.prevent v-model="valid">
            <fe-remote-combo
                label="Select a School Year"
                :items="schoolYears"
                v-model="config.school_year_id"
                :rules="this.$fieldValidators('select', null, {required: true})"
                byId
            />

            <fe-label>Dashboard Name</fe-label>
            <v-text-field
                v-model="config.name"
                counter=25
                :rules="this.$fieldValidators('text', null, {required: true, limit: 25})"
                :autofocus="!!this.clone"
                flat solo dense clearable
            />

            <toggle-button v-if="showDynamicToggle" :items="toggleItems" v-model="dashType" class="mb-6" @input="typeToggle"/>

            <div v-if="dashType.show === 'standard'" class="d-flex mb-4">
                <fe-switch
                    v-model="config.global_filters"
                    hide-details
                />

                <fe-label style="margin-top: 6px">Apply global dashboard-level filters (optional)</fe-label>

                <fe-info-tip
                    class="ml-2"
                    tooltip="Custom dashboard filters will apply to all visualizations in a dashboard. You will not be able to apply custom filters to individual visualizations."
                />
            </div>

            <div v-else class="mb-4">
                <fe-label style="margin-top: 6px">
                    Apply dynamic dashboard scope
                    <div class='title-tip-adj'>
                        <fe-info-tip
                            tooltip="Visualizations in this dashboard will use data for each user depending on the
                            students related to them at the level defined by the scope."
                        />
                    </div>
                </fe-label>

                <v-radio-group row v-model="config.scope">
                    <v-radio v-for="r in ruleOptions" :key="r.id" :label="r.name" :value="r.id"/>
                </v-radio-group>
            </div>

            <student-filters
                v-if="config.global_filters"
                :filters="studentFilters"
                :dashboard="config"
                minDays
                global
                @change="studentFilters = $event"
            />

            <fe-label>Description</fe-label>

            <v-textarea
                v-model="config.description"
                counter=255
                rows=4
                :rules="this.$fieldValidators('text', null, {required: true, limit: 255})"
                flat solo dense no-resize
            />

            <div class="d-flex" v-if="clone">
                <v-checkbox
                    class="shrink mt-0 pt-0 mr-3"
                    v-model="cloneDepth.goals"
                    label="Clone Goals"
                    hide-details
                />

                <v-checkbox
                    class="shrink mt-0 pt-0 mr-3"
                    v-model="cloneDepth.objectives"
                    label="Clone Objectives"
                    hide-details
                />

                <v-checkbox
                    class="shrink mt-0 pt-0 mr-3"
                    v-model="cloneDepth.actionItems"
                    label="Clone Action Items"
                    hide-details
                />

                <v-checkbox
                    class="shrink mt-0 pt-0 mr-3"
                    v-show="cloneDepth.actionItems"
                    v-model="cloneDepth.assignees"
                    label="Clone Assignees"
                    hide-details
                />
            </div>
        </v-form>

        <fe-dialog
            v-if="overrideWarning.show"
            :header="false"
            width="700"
            acceptButtonText="Confirm"
            dismissButtonText="Cancel"
            dismissable
            persistent
            @dismiss="config.global_filters = false; overrideWarning.show = false"
            @accept="overrideWarning.show = false"
        >
            <div class="mt-6" v-html="confirmText"></div>
        </fe-dialog>
    </fe-dialog>
</template>

<script>
import {mapState} from "vuex"
import StudentFilters from "./fields/StudentFilters"
import ToggleButton from '@/components/common/button/ToggleButton'

export default {
    name: "DashConfig",

    components: {StudentFilters, ToggleButton},

    props: {
        edit: {
            type: Object,
            required: false
        },
        getSSValues: {
            type: Function,
            required: true
        },
        clone: {
            type: Object,
            required: false
        }
    },

    data() {
        return {
            ready: false,
            valid: false,
            overrideWarning: {
                show: false,
                shown: false
            },
            config: {
                name: undefined,
                school_year_id: undefined,
                description: undefined,
                global_filters: undefined,
                scope: undefined,
                saved_search_id: undefined
            },
            dashType: undefined,
            savedSearch: {
                school_id: undefined,
                grade_id: undefined,
                ethnicity: undefined,
                disability_type_id: undefined,
                gender: undefined,
                tag_id: undefined,
                meal_status_id: undefined,
                ell_flag: undefined,
                ell_level_id: undefined,
                student_active_flag: undefined,
                student_active_date: undefined,
                min_possible_attendance_days: undefined
            },
            cloneDepth: {
                goals: false,
                objectives: false,
                actionItems: false,
                assignees: false
            },
            toggleItems: [{
                name: 'Standard',
                show: 'standard'
            }, {
                name: 'Dynamic',
                show: 'dynamic'
            }],
            ruleOptions: [{
                name: 'School',
                id: 'school'
            }, {
                name: 'Grade',
                id: 'grade'
            }, {
                name: 'Class',
                id: 'class'
            }]
        }
    },

    computed: {
        ...mapState('global', ['shareableStores']),

        title() {
            let output
            if (this.clone) {
                output = 'Duplicate'
            } else if (this.edit) {
                output = 'Edit'
            } else {
                output = 'New'
            }
            output += ' Dashboard'
            return output
        },

        acceptButtonText() {
            return this.edit ? 'Done' : 'Save'
        },

        confirmText() {
            if (this.clone) return "You are attempting to duplicate a dashboard and apply global dashboard-level filters. Applying global dashboard-level filters will override any existing filters on the duplicated dashboard."
            return "You are attempting to apply dashboard-level filters to a dashboard that may already have filters applied. Applying global dashboard-level filters will override any existing filters."
        },

        studentFilters: {
            get() {
                return {
                    school_id: this.savedSearch.school_id,
                    grade_id: this.savedSearch.grade_id,
                    ethnicity: this.savedSearch.ethnicity,
                    disability_type_id: this.savedSearch.disability_type_id,
                    gender: this.savedSearch.gender,
                    tag_id: this.savedSearch.tag_id,
                    min_possible_attendance_days: this.savedSearch.min_possible_attendance_days?.id,
                    meal_status_id: this.savedSearch.meal_status_id,
                    ell_flag: this.savedSearch.ell_flag,
                    ell_level_id: this.savedSearch.ell_level_id,
                    student_active_flag: this.savedSearch.student_active_flag,
                    student_active_date: this.savedSearch.student_active_date
                }
            },

            set(v) {
                Object.keys(v).forEach(x => {
                    if (this.savedSearch.hasOwnProperty(x)) {
                        this.savedSearch[x] = v[x]
                    }
                })
            }
        },

        schoolYears() {
            let schoolYears = this.shareableStores.school_years.filter(x => x.active)
            return this.$_.sortBy(schoolYears, 'year_end').reverse()
        },

        dynamicEnabled() {
            return this.$store.getters['flags/flags']['Dynamic_Dashboards'] === true
        },

        showDynamicToggle() {
            if (!this.dynamicEnabled) return false
            if (this.clone) return true
            return !this.edit
        }
    },

    watch: {
        "cloneDepth.actionItems"(x) {
            if (!x) this.cloneDepth.assignees = false
        },

        "config.global_filters"(x) {
            if (!this.overrideWarning.shown && this.ready && x && !this.config.saved_search_id) {
                this.overrideWarning.show = true
                this.overrideWarning.shown = true
            }
        }
    },

    mounted() {
        let awaiting = []
        if (this.clone) {
            this.overrideWarning.shown = !this.clone.has_vis_filters
            if (!this.dynamicEnabled) delete this.clone.scope
            this.config = this.setConfig(this.config, this.clone)
            delete this.config.has_vis_filters
            if (this.config.global_filters && this.config.saved_search_id) {
                awaiting.push(this.getSSValues(this.config.saved_search_id, this.savedSearch))
            }
        } else if (this.edit) {
            this.config = this.setConfig(this.config, this.edit)
            this.overrideWarning.shown = !this.config.configs.filter(x => !!x.saved_search_id).length
            if (this.config.global_filters && this.config.saved_search_id) {
                awaiting.push(this.getSSValues(this.config.saved_search_id, this.savedSearch))
            }
        }
        Promise.all(awaiting).then(() => {
            this.dashType = this.config.scope ? this.toggleItems[1] : this.toggleItems[0]
            this.ready = true
        })
    },

    methods: {
        setConfig(base, edit) {
            return Object.assign({}, base, edit)
        },

        save() {
            let config = this.config
            if (this.clone) {
                this.$emit('saveClone', {config, depth: this.cloneDepth}, this.savedSearch)
            } else {
                this.$emit('save', config, this.savedSearch)
            }
        },

        typeToggle(type) {
            if (type.show === 'dynamic') {
                this.config.scope = 'school'
            } else {
                this.config.scope = undefined
            }
        }
    }
}
</script>

<style scoped>
.title-tip-adj {
    display: inline-block;
    position: relative;
    top: -3px;
    left: 5px;
}
</style>

<template>
    <div class="flex-fill flex-column no-scroll dashboard">
        <fe-dialog
            v-if="undefined === useNewAD"
            dismissButtonText="No Thanks"
            acceptButtonText="Switch to New View"
            @accept="setNewVersionDefault(true)"
            @dismiss="setNewVersionDefault(false)"
            width="500"
            :header="false"
            persistent
        >
            <div class="mt-6">
                <p><b>There is a new look for Achievement Dashboard!</b></p>
                <p>Would you like to have Early Access to the new look?</p>
                <p>You can return to the classic view at any time by changing your preference in User Settings.</p>
            </div>
        </fe-dialog>

        <div v-else-if="useNewAD" class="flex-fill flex-column no-scroll py-4 px-6">
            <landing-page
                v-show="!activeDashboard"
                :dashboards="dashboards"
                @new="dashConfig = true"
                @select="activeDashboard = $event"
            />

            <dashboard
                v-if="activeDashboard"
                ref="dashboard"
                :key="refreshKey"
                :dashboard="activeDashboard"
                :getSavedSearchValues="getSavedSearchValues"
                :prepSavedSearchIds="prepSavedSearchIds"
                :getObjId="getObjId"
                @update="loadDashboards"
                @edit="editDash"
                @clone="cloneDash"
                @delete="deleteDash"
                @clear="activeDashboard = undefined"
                @reload="reloadActiveDashboard"
            />

            <dash-config
                v-if="dashConfig"
                :edit="activeDashboard"
                :clone="clone"
                :getSSValues="getSavedSearchValues"
                @close="dashConfig = false"
                @save="save"
                @saveClone="saveClone"
            />

            <fe-crud ref="dashboardsCrud" :config="dashboardsCrudConfig" autoload @read="dashboards = $event"/>
        </div>

        <i-frame-renderer v-else :cfg="iframeCfg" app="achievement_dashboard"/>
    </div>
</template>

<script>
import IFrameRenderer from '@/components/common/IFrameRenderer'
import LandingPage from './LandingPage'
import Dashboard from "./Dashboard"
import DashConfig from "./config/DashConfig"
import {mapState} from "vuex"

export default {
    name: 'AchievementDashboard',

    components: {IFrameRenderer, LandingPage, Dashboard, DashConfig},

    props: ["selectedDashboardId"],

    data() {
        return {
            dashboards: [],
            refreshKey: 0,
            activeDashboard: undefined,
            clone: undefined,
            dashConfig: false,
            useNewAD: undefined
        }
    },

    computed: {
        ...mapState('global', ['sessionUser', 'userPreferences']),

        dashboardsCrudConfig() {
            let cfg = this.$_.cloneDeep(this.$models.dashboards)
            cfg.read.params.district_default = `0`
            return cfg
        },

        iframeCfg() {
            return {
                events: {
                    'switch-to-new': () => {
                        this.setNewVersionDefault(true)
                    }
                },
                identifier: 'early-access'
            }
        }
    },

    mounted() {
        this.$store.dispatch('global/loadDemographics')
        let userValue = this.userPreferences?.USE_NEW_AD?.user_value
        switch (userValue) {
            case "0":
                this.useNewAD = false
                break
            case "1":
                this.useNewAD = true
                break
        }
    },

    methods: {
        setNewVersionDefault(res) {
            let rec = {
                user_id: this.sessionUser.user.id,
                USE_NEW_AD: res
            }
            this.$axios.post('preferences.php?action=update', JSON.stringify(rec))
                .then(resp => {
                    this.$snackbars.$emit('new', {text: resp.data.msg, usage: 'success'})
                    this.useNewAD = res
                })
        },

        getSavedSearchValues(id, savedSearch = {}) {
            return new Promise(resolve => {
                if (!id) {
                    resolve({savedSearch})
                }

                let url = this.$models.getUrl('savedSearchValues', 'read') + '&id=' + id

                this.$axios.get(url)
                    .then(res => {
                        let ssValues = res.data.saved_search_values
                        Object.keys(ssValues).forEach(key => {
                            let value = ssValues[key]
                            if (value.length) {
                                let keyLower = key.toLowerCase()
                                let multi = [
                                    'school_id',
                                    'grade_id',
                                    'ethnicity',
                                    'disability_type_id',
                                    'tag_id',
                                    'incident_behavior_type_id',
                                    'incident_behavior_id',
                                    'incident_response_id',
                                    'target_descriptor_id',
                                    'demographic',
                                    'meal_status_id',
                                    'ell_level_id',
                                ]

                                if (multi.includes(keyLower)) {
                                    savedSearch[keyLower] = value
                                } else if (keyLower === 'student_active_date') {
                                    savedSearch[keyLower] = value[0].id
                                } else {
                                    savedSearch[keyLower] = value[0]
                                }
                            }
                        })

                        resolve({savedSearch})
                    })
            })
        },

        async demoCheck(filter) {
            let optionIds = []
            let newFilters = []
            let ops = ['gt:', 'gte:', 'lt:', 'lte:', 'bt:', 'eq:', 'has:', 'start:', 'end:']
            
            filter.forEach(id => {
                if (ops.some(op => id.includes(op))) {
                    newFilters.push(id)
                } else {
                    optionIds.push(parseInt(id.split(':')[1]))
                }
            })

            await this.$axios.get(this.$models.getUrl('demographicOption', 'read'))
                .then(r => {
                    let options = r.data.demographic_option
                    options.forEach(o => {
                        if (optionIds.indexOf(o.id) !== -1 && o.display_name_group) {
                            o.display_name_group.forEach(n => {
                                newFilters.push(o.demographic_id + ':' + n)
                            })
                        } else if (optionIds.indexOf(o.id) !== -1) {
                            newFilters.push(o.demographic_id + ':' + o.id)
                        }
                    })
                })

            return newFilters
        },

        displayNameCheck(filter) {
            if (!filter.length) return filter

            let studentFilters = []
            let newFilters = []

            filter.forEach(f => {
                if (f.display_name_group) {
                    // create multiple objects from display_name_group contents
                    let dng = f.display_name_group
                    dng.forEach((g,j) => {
                        studentFilters[j] = {
                            ...f,
                            // add these so they get saved to the db (saved_search)
                            id: g,
                            name: g,
                            value: g,
                        }
                        newFilters.push(studentFilters[j])
                    })
                } else {
                    newFilters.push(f)
                }
            })

            return newFilters
        },

        async prepSavedSearchIds(config, operation) {
            let output = {}

            // if v has display_name_group, split group and create new objects
            // as if user sent multiple options
            // do this for: ethnicity, gender, meal_status_id, disability_type_id, ell_level_id
            if (config.ethnicity) config.ethnicity = this.displayNameCheck(config.ethnicity)
            if (config.gender) config.gender = this.displayNameCheck(config.gender)
            if (config.meal_status_id) config.meal_status_id = this.displayNameCheck(config.meal_status_id)
            if (config.disability_type_id) config.disability_type_id = this.displayNameCheck(config.disability_type_id)
            if (config.ell_level_id) config.ell_level_id = this.displayNameCheck(config.ell_level_id)

            // ...also do this for regular demographics
            if (config.demographic && config.demographic.length) config.demographic = await this.demoCheck(config.demographic)

            this.$_.forEach(config, (v, k) => {
                if (v) {
                    if (!this.$_.isArray(v)) v = [v]
                    output[k] = v.map(x => this.getObjId(x)).join(',')
                }
            })

            if (operation === 'create') {
                output.kpi_force = 1
            }

            return [output]
        },

        getObjId(v) {
            if (!v) return

            // for ethnicity and gender
            // add id with value of display_name or value
            if (!v.hasOwnProperty('id')) Object.assign(v, {id: v.value})

            let id = v.hasOwnProperty('id') ? v.id : v
            if (v.rcExclude) id = '!' + id
            if (typeof id === 'string') {
                id = id?.replaceAll(/,/g, "comma;")
            }

            return id
        },

        loadDashboards() {
            return this.$refs.dashboardsCrud.read()
        },

        editDash() {
            this.clone = undefined
            this.dashConfig = true
        },

        cloneDash(config) {
            this.clone = {
                description: config.description,
                school_year_id: config.school_year_id,
                global_filters: config.global_filters,
                saved_search_id: config.saved_search_id,
                has_vis_filters: !!config.configs.filter(x => !!x.saved_search_id),
                scope: config.scope
            }
            this.dashConfig = true
        },

        deleteDash(config) {
            let msg = 'You are attempting to delete ' + config.name + '.'
            msg += '<br>This action cannot be undone.'
            msg += '<br>Are you sure you want to delete this dashboard?'

            this.$confirmDelete(
                config,
                () => {
                    this.$refs.dashboardsCrud.destroy(config)
                        .then(() => {
                            this.loadDashboards()
                            this.activeDashboard = undefined
                        })
                },
                null,
                msg
            )
        },

        saveClone(config, savedSearch = {}) {
            let url = this.$models.getUrl('dashboards', 'clone')
            let awaiting = []
            config.id = this.activeDashboard.id

            if (config.config.global_filters) {
                var args = this.prepSavedSearchIds(savedSearch, 'create')
                awaiting.push(this.postSavedSearch(null, 'create', args))
            } else if (config.config.saved_search_id) {
                config.config.saved_search_id = null
            }

            Promise.all(awaiting)
                .then(res => {
                    if (res[0]?.data?.saved_searches?.length) {
                        config.config.saved_search_id = res[0].data.saved_searches[0].id
                    }

                    this.$axios.post(url, [config])
                        .then(res => {
                            let id = res?.data?.data[0].id
                            if (id) {
                                this.loadDashboards()
                                    .then(() => {
                                        let newDash = this.dashboards.filter(x => x.id === id)[0]

                                        let innerAwaiting = []
                                        let configsWithSS = newDash.configs.filter(x => !['section', 'googlesheet'].includes(x.config.type))

                                        if (newDash.saved_search_id && configsWithSS.length) {
                                            innerAwaiting.push(this.updateVisSavedSearch(configsWithSS, args))
                                        }

                                        Promise.all(innerAwaiting).then(() => {
                                            this.dashConfig = false
                                            this.refreshKey += 1
                                            this.activeDashboard = newDash
                                            this.$nextTick(() => {
                                                this.$refs.dashboard.reloadVisualizations()
                                            })
                                        })
                                    })
                            }
                        })
                })
        },

        async save(config, savedSearch = {}) {
            let operation = config.id ? 'update' : 'create'
            let awaiting = []

            if (config.global_filters) {
                let ssOperation = config.saved_search_id ? 'update' : 'create'
                var args = await this.prepSavedSearchIds(savedSearch, ssOperation)
                awaiting.push(this.postSavedSearch(config.saved_search_id, ssOperation, args))
            } else if (config.saved_search_id) {
                config.saved_search_id = null
            }

            Promise.all(awaiting)
                .then(res => {
                    if (res[0]?.data?.saved_searches?.length) {
                        config.saved_search_id = res[0].data.saved_searches[0].id
                    }

                    let innerAwaiting = []
                    let reload = false

                    if (operation !== 'create') {
                        let configsWithSS = config.configs.filter(x => !['section', 'googlesheet'].includes(x.config.type))
                        if (config.saved_search_id && configsWithSS.length) {
                            reload = true
                            innerAwaiting.push(this.updateVisSavedSearch(configsWithSS, args))
                        }
                    }

                    Promise.all(innerAwaiting)
                        .then(() => {
                            this.$refs.dashboardsCrud[operation](config)
                                .then(res => {
                                    this.loadDashboards().then(() => {
                                        this.activeDashboard = res.data.kpi_dashboard[0]
                                        if (reload) {
                                            this.refreshKey += 1
                                            this.$nextTick(() => {
                                                this.$refs.dashboard.reloadVisualizations()
                                            })
                                        }
                                        this.dashConfig = false
                                    })
                                })
                        })
                })
        },

        updateVisSavedSearch(configs, global) {
            let awaiting = []

            configs.forEach(x => {
                this.getSavedSearchValues(x.saved_search_id)
                    .then(res => {
                        let operation = x.saved_search_id ? 'update' : 'create'
                        let existing = this.prepSavedSearchIds(res.savedSearch, operation)
                        let globalFilters = this.$_.cloneDeep(global[0])
                        if (x.config.type === 'tag' && globalFilters.hasOwnProperty('tag_id')) {
                            delete globalFilters.tag_id
                        }
                        let newSS = Object.assign({}, existing[0], globalFilters)

                        awaiting.push(this.postSavedSearch(x.saved_search_id, operation, [newSS])
                            .then(res => {
                                if (x.saved_search_id !== res.data.saved_searches[0].id) {
                                    x.saved_search_id = res.data.saved_searches[0].id
                                    awaiting.push(this.updateConfig(x))
                                }
                            }))
                    })
            })

            return new Promise(resolve => {
                Promise.all(awaiting).then(resolve(true))
            })
        },

        postSavedSearch(id, operation, args) {
            let property = id ? '&property=dashboard&saved_search_id=' + id : ''
            let ssUrl = this.$models.getUrl('savedSearch', operation) + property
            return this.$axios.post(ssUrl, {'saved_searches': args})
        },

        updateConfig(data) {
            let config = this.$_.cloneDeep(data)
            let cfg = this.$_.cloneDeep(config.config)
            if (config.id) delete config.i
            Object.keys(cfg).forEach(x => {
                if (config.hasOwnProperty(x)) {
                    if (x === 'colors' && this.$_.isArray(config[x])) {
                        let colors = {}
                        config[x].forEach(y => {
                            if (y.color) {
                                colors[y.name] = y.color
                            }
                        })
                        cfg[x] = colors
                    } else {
                        cfg[x] = config[x]
                    }
                    delete config[x]
                }
            })
            config.config = JSON.stringify(cfg)
            let url = this.$models.getUrl('visualizations', 'update')
            return this.$axios.post(url, {'kpi_config': config})
        },

        reloadActiveDashboard() {
            let active = this.activeDashboard
            this.$refs.dashboardsCrud.read()
                .then(response => {
                    this.activeDashboard = response.data.kpi_dashboard.find(x => x.id == active.id)
                })
        }
    }
}
</script>

<style lang="scss" scoped>
.dashboard {
    background: #F5F6F7;
}
</style>
